export const POLLING_INTERVAL = 3000

export const BASEDIR = '/smartcontent'

export const CDN_COS = 'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com'

export const TOKEN_GATEWAY = 'https://service-5z1ifpan-1257411467.bj.apigw.tencentcs.com/release/Token_Service'

export const CONFIG_GATEWAY = 'https://service-6ube6p9n-1257411467.bj.apigw.tencentcs.com/release/aux'

export const TOKEN_GATEWAY_ACCESS_CODE = 'KHtv7ite1TgwwjzJ'

export const BACK_ENV = process.env.REACT_APP_ENV === 'product' ? 'product' : 'beta'

export const TMP_KEY_URL = 'https://service-6ube6p9n-1257411467.bj.apigw.tencentcs.com/release/STS'

export const LyricsResults = [
  [
    {
      head: '\u6211\u7528\u771f\u5fc3\u8bdd\u53bb\u5927\u5192\u9669',
      pattern: 'AAABBBCCC',
      lyricResult:
        '\u6211\u7528\u771f\u5fc3\u8bdd\u53bb\u5927\u5192\u9669(ian)\n\u7231 \u5c31\u5728\u4f20\u8bf4\u4e2d\u90a3\u662f\u6700\u597d\u7ec8\u70b9(ian)\n\u4e00\u76f4\u5230\u5fd8\u8bb0\u4e86\u65f6\u95f4(ian)\n\u8fd8\u6709\u6d77\u9e25\u770b\u7740\u6d77\u9762(ian)\n\u4e0d\u7ba1\u5929\u6daf\u6d77\u89d2\u6709\u591a\u8fdc(uan)\n\u6211\u4f1a\u73cd\u60dc\u8fd9\u4efd\u5bf9\u4f60\u7684\u795d\u613f(uan)\n\u7136\u800c\u8fd9\u9996\u6b4c\u53ea\u5199\u7ed9\u66fe\u7ecf\u7684\u67d0\u4e2a\u5973\u4eba(en)\n\u662f\u65f6\u5019\u9009\u62e9\u751f\u547d\u4e4b\u6240\u4ee5\u8fde\u81ea\u5df1\u90fd\u4e0d\u6562\u76f8\u4fe1(in)\n\u6211\u5374\u8981\u5ac1\u7ed9\u4e09\u5341\u5c81\u7684\u7537\u4eba(en)',
      rapResult:
        '\u6211\u7528\u771f\u5fc3\u8bdd\u53bb\u5927\u5192\u9669(ian)\n\u6211\u60f3\u8bf4\u6211\u4f1a\u7231\u4f60\u591a\u4e00\u70b9(ian)\n\u662f\u4e0d\u662f\u6211\u4eec\u7684\u611f\u60c5\u6ca1\u6709\u6c38\u8fdc(uan)\n\u53ef\u542c\u7740\u542c\u7740\u5c31\u89c9\u5f97\u7d2f\u4e86(e)\n\u80a9\u62ab\u6218\u58eb\u6545\u4e8b \u8c01\u4eba\u613f\u4f3c\u9996\u8bd7\u6b4c(e)\n\u53ea\u56e0\u660e\u4e89\u6697\u593a(uo)\n\u6ee1\u8eab\u9c9c\u8840\u6765\u5f81\u6218\u6740\u65e0\u8d66(e)\n\u6570\u4e0d\u6e05\u7684\u6b65\u4f10 \u8e29\u8fc7\u811a\u4e0b\u6c5f\u5c71\u53c8\u5982\u4f55(e)\n\u591a\u4e48\u5e0c\u671b\u5728\u67d0\u4e00\u5929\u80fd\u518d\u89c1\u5230\u6211(o)',
    },
  ],
  [
    {
      head: '\u751f\u547d\u5c31\u50cf\u662f\u4e00\u4e2a\u4e00\u4e2a\u8a93\u8a00',
      pattern: 'AAABBBCCC',
      lyricResult:
        '\u751f\u547d\u5c31\u50cf\u662f\u4e00\u4e2a\u4e00\u4e2a\u8a93\u8a00(an)\n\u66fe\u7ecf\u8bb8\u4e0b\u7684\u5fc3\u613f \u4e00\u76f4\u4e0d\u80fd\u91cd\u73b0(ian)\n\u968f\u7740\u4f60\u6e10\u884c\u6e10\u8fdc(uan)\n\u7559\u5728\u56de\u5fc6\u91cc\u6253\u8f6c(uan)\n\u8fd8\u4ee5\u4e3a\u6211\u8ba1\u7b97\u8fc7\u4e86\u660e\u5929(ian)\n\u6211\u4e5f\u5e94\u8be5\u4efb\u6027\u81ea\u5df1\u6307\u6307\u70b9\u70b9(ian)\n\u867d\u7136\u6211\u65e9\u5df2\u6ca1\u6709\u813e\u6c14\u522b\u8bf4\u8bdd\u90a3\u4e48\u5c0f\u5fc3(in)\n\u8eab\u8fb9\u6709\u4f60\u7684\u4e16\u754c\u6211\u624d\u4f1a\u5b89\u5fc3(in)\n\u5176\u5b9e\u4f60\u53ef\u4e0d\u5fc5\u518d\u505a\u5973\u4eba(en)',
      rapResult:
        '\u751f\u547d\u5c31\u50cf\u662f\u4e00\u4e2a\u4e00\u4e2a\u8a93\u8a00(an)\n\u53cd\u6b63\u6211\u5df2\u5c1d\u8fc7\u4e86\u5fc3\u9178 \u518d\u4e5f\u4e0d\u4f1a\u91cd\u73b0(ian)\n\u660e\u5929\u8d77\u4e0d\u7ba1\u4ec0\u4e48\u4e8b\u60c5\u90fd\u8981\u52c7\u6562\u627f\u62c5(an)\n\u611f\u8c22\u4e0a\u5929\u8ba9\u6211\u9047\u89c1\u4f60(i)\n\u53ea\u60f3\u628a\u4f60\u7d27\u7d27\u62b1\u5728\u6000\u91cc(i)\n\u6bcf\u5f53\u6211\u7741\u5f00\u773c\u775b \u770b\u7740\u8fdc\u65b9\u7684\u4f60(i)\n\u6211\u75db\u54ed\u7684\u65f6\u5019\u5c31\u8fd9\u6837\u9ed8\u9ed8\u5730\u597d\u597d\u7231\u4f60(i)\n\u4ece\u4eca\u4ee5\u540e\u4e0d\u518d\u4e3a\u8c01\u800c\u54ed\u6ce3(i)\n\u4e0d\u613f\u610f\u8ffd\u5bfb\u6ca1\u6709\u4eba\u7684\u95ee\u9898\u6211\u66fe\u7ecf\u8ff7\u5931\u81ea\u5df1(i)',
    },
  ],
  [
    {
      head: '\u6545\u4e8b\u7684\u5c0f\u9ec4\u82b1',
      pattern: 'AAABBBCCC',
      lyricResult:
        '\u6545\u4e8b\u7684\u5c0f\u9ec4\u82b1(ua)\n\u5feb\u4e50\u70b9\u7740\u82b1 \u7f16\u4e00\u6735\u9001\u7ed9\u5979(a)\n\u5a03\u5a03\u54ed\u5440 \u5a03\u5a03\u7b11\u5566(a)\n\u4e0d\u8bf4\u8bdd\u4f60\u7728\u7709\u6bdb\u6211\u5c31\u7ffb\u8138\u4e86(e)\n\u4f60\u786e\u5b9a\u559c\u6b22\u6211(o)\n\u60f3\u6c38\u8fdc\u966a\u7740\u6211(o)\n\u8c01\u90fd\u4e0d\u613f\u610f\u518d\u591a\u505c\u7559(iu)\n\u8c01\u4e5f\u65e0\u6cd5\u5f3a\u6c42(iu)\n\u7b80\u5355\u80fd\u76f8\u7231 \u4e0d\u9700\u8981\u7406\u7531(ou)',
      rapResult:
        '\u6545\u4e8b\u7684\u5c0f\u9ec4\u82b1(ua)\n\u5176\u5b9e\u4f60\u5e76\u4e0d\u662f\u6bcf\u4e2a\u4eba\u90fd\u559c\u6b22\u5979(a)\n\u5916\u9762\u4e0b\u96e8\u4e86 \u4f60\u4e5f\u4f1a\u4e56\u4e56\u542c\u6211\u591a\u8bf4\u8bdd(ua)\n\u6211\u5fc3\u7231\u7684\u59d1\u5a18 \u5343\u4e07\u4e0d\u8981\u8d70\u5f00(ai)\n\u6211\u5728\u65e0\u5c3d\u9ed1\u591c\u7b49\u5f85(ai)\n\u4e0d\u8ba9\u660e\u5929\u5230\u6765(ai)\n\u522b\u89c9\u5f97\u6709\u70b9\u5947\u602a(uai)\n\u5982\u679c\u771f\u60c5\u7edd\u5bf9\u53ea\u6c42\u4e00\u70b9\u5766\u767d(ai)\n\u5f53\u6211\u5df2\u660e\u767d\u653e\u624b\u4f60\u7684\u7231(ai)',
    },
  ],
  [
    {
      head: '\u7ed9\u6211\u4e00\u628a\u5251',
      pattern: 'AAABBBCCC',
      lyricResult:
        '\u7ed9\u6211\u4e00\u628a\u5251(ian)\n\u5f53\u5bd2\u98ce\u7a7f\u8d8a\u8fc7\u6591\u9a6c\u7ebf(ian)\n\u6ca1\u4eba\u5728\u8eab\u8fb9(ian)\n\u6211\u60f3\u8981\u7684\u5feb\u4e50\u4f60\u770b\u4e0d\u89c1(ian)\n\u4fa7\u8033\u542c\u7740\u8fd9\u6bb5\u65cb\u5f8b\u91cc\u9762 \u56de\u5473\u90a3\u77ac\u95f4(ian)\n\u5176\u5b9e\u4e0d\u7528\u8bf4\u6211\u4eec\u4f1a\u6709\u660e\u5929(ian)\n\u53ef\u8fd9\u8106\u5f31\u7684\u611f\u60c5\u600e\u6837\u624d\u7518\u5fc3(in)\n\u65e5\u540e\u4ea6\u4e0d\u6562\u518d\u53bb\u7231\u522b\u4eba(en)\n\u505a\u4e2a\u6700\u7231\u7684\u4eba(en)',
      rapResult:
        '\u7ed9\u6211\u4e00\u628a\u5251(ian)\n\u4e0d\u65ad\u731c\u6d4b\u8bd5\u7740\u8d85\u8d8a\u81ea\u5df1\u7684\u5e95\u7ebf(ian)\n\u4e16\u4e0a\u66fe\u51e0\u591a\u8981\u8138 \u5fc3\u91cc\u65e5\u5e38\u53ea\u770b\u4f60\u8868\u73b0(ian)\n\u7eb5\u662f\u6602\u9996\u633a\u80f8\u53cc\u65b9\u90fd\u5728\u53d8(ian)\n\u53c8\u4f55\u7528\u77e5\u9053\u53bb\u638c\u63e1\u6bcf\u573a\u79bb\u5408\u60b2\u6b22\u751f\u800c\u65e0\u61be(an)\n\u4f46\u5374\u65e0\u6cd5\u505c\u6b62\u76f8\u7231\u7684\u90a3\u4e2a\u77ac\u95f4(ian)\n\u6211\u4eec\u518d\u4e5f\u65e0\u6cd5\u56de\u5230\u90a3\u5e74\u79cb\u5929(ian)\n\u9192\u6765\u65f6\u522b\u7ba1\u547d\u8fd0\u5450\u558a\u4f60\u662f\u5426\u542c\u5f97\u89c1(ian)\n\u6211\u4eec\u4fe9\u6b65\u5c65\u8e52\u8dda\u5f80\u524d(ian)',
    },
  ],
]

export const SeperateResults = [
  {
    vocals:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample1/t4oz2qinput_vocals.wav',
    drums:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample1/t4oz2qinput_drums.wav',
    bass:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample1/t4oz2qinput_bass.wav',
    accompaniment:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample1/t4oz2qinput_accompaniment.wav',
  },
  {
    vocals:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample2/po8v8minput_vocals.wav',
    drums:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample2/po8v8minput_drums.wav',
    bass:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample2/po8v8minput_bass.wav',
    accompaniment:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample2/po8v8minput_accompaniment.wav',
  },
  {
    vocals:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample3/d8a4wqinput_vocals.wav',
    drums:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample3/d8a4wqinput_drums.wav',
    bass:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample3/d8a4wqinput_bass.wav',
    accompaniment:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample3/d8a4wqinput_accompaniment.wav',
  },
  {
    vocals:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample4/a72qkfinput_vocals.wav',
    drums:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample4/a72qkfinput_drums.wav',
    bass:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample4/a72qkfinput_bass.wav',
    accompaniment:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/seperate/sample4/a72qkfinput_accompaniment.wav',
  },
]

export const GenreResults = [
  {
    chinese:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample1/4f4lojinput_chinese.wav',
    pop: 'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample1/4f4lojinput_pop.wav',
    light:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample1/4f4lojinput_light.wav',
    opera:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample1/4f4lojinput_opera.wav',
  },
  {
    chinese:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample2/zz1ms8input_chinese.wav',
    pop: 'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample2/zz1ms8input_pop.wav',
    light:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample2/zz1ms8input_light.wav',
    opera:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample2/zz1ms8input_opera.wav',
  },
  {
    chinese:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample3/pnz4atinput_chinese.wav',
    pop: 'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample3/pnz4atinput_pop.wav',
    light:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample3/pnz4atinput_light.wav',
    opera:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample3/pnz4atinput_opera.wav',
  },
  {
    chinese:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample4/9ejm1linput_chinese.wav',
    pop: 'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample4/9ejm1linput_pop.wav',
    light:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample4/9ejm1linput_light.wav',
    opera:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/genre/sample4/9ejm1linput_opera.wav',
  },
]

export const WzryResults = [
  [
    {
      begin: '3.0',
      end: '12.0',
      tag: ['huamulan', 'Slay', 'FlashEscape'],
      caption:
        '\u82b1\u6728\u5170\u95ea\u73b0\u9003\u751f\uff0c\u5b9e\u73b0\u51fb\u8d25\u654c\u4eba\uff0c\u5965\u529b\u7ed9\uff01',
      barrage: [
        '\u95ea\u73b0\u9003\u751f\uff0c\u9022\u51f6\u5316\u5409',
        '\u51fb\u8d25\u654c\u4eba\uff0c\u65e0\u654c\u4e86',
      ],
    },
  ],
  [
    {
      begin: '1.0',
      end: '24.0',
      tag: ['zhugeliang', 'LargeScaleTeamFight', 'Slay', 'MultiPlayerKill'],
      caption:
        '\u8bf8\u845b\u4eae\u5728\u5927\u89c4\u6a21\u56e2\u6218\u4e2d\uff0c\u53c2\u4e0e\u591a\u4eba\u51fb\u6740\u654c\u4eba\uff0c\u5b9e\u73b0\u51fb\u8d25\u654c\u4eba\uff0c\u5e72\u5f97\u6f02\u4eae\uff01',
      barrage: [
        '\u5927\u89c4\u6a21\u56e2\u6218\uff0c\u52bf\u4e0d\u53ef\u6321',
        '\u591a\u6740\uff0c\u8840\u6761\u6d88\u5931',
        '\u51fb\u8d25\u654c\u4eba\uff0c\u4eca\u65e5\u624b\u611f\u5c1a\u4f73',
      ],
    },
  ],
  [
    {
      begin: '1.0',
      end: '14.0',
      tag: ['huamulan', 'SmallScaleTeamFight', 'DoubleKill', 'SoloKill', 'OverTowerKill'],
      caption:
        '\u82b1\u6728\u5170\u5728\u5c0f\u89c4\u6a21\u56e2\u6218\u4e2d\uff0c\u53c2\u4e0e\u8d8a\u5854\u5f3a\u6740\u654c\u4eba\uff0c\u5355\u6740\u654c\u4eba\uff0c\u5b9e\u73b0\u4e8c\u8fde\u51fb\u7834\uff0c\u5965\u529b\u7ed9\uff01',
      barrage: [
        '\u5c0f\u89c4\u6a21\u56e2\u6218\uff0c\u4ee5\u6218\u517b\u6218',
        '\u5355\u6740\uff0c\u5175\u5203\u76f8\u63a5',
        '\u8d8a\u5854\u5f3a\u6740\uff0c\u9756\u5eb7\u803b\uff0c\u72b9\u672a\u96ea\uff1b\u81e3\u5b50\u6068\uff0c\u4f55\u65f6\u706d',
        '\u4e8c\u8fde\u51fb\u7834\uff0c\u72af\u6211\u5927\u5510\u8005\uff0c\u867d\u8fdc\u5fc5\u8bdb',
      ],
    },
  ],
  [
    {
      begin: '1.0',
      end: '14.0',
      tag: ['zhugeliang', 'LargeScaleTeamFight', 'TripleKill', 'MultiPlayerKill', 'FlashEscape'],
      caption:
        '\u8bf8\u845b\u4eae\u5728\u5927\u89c4\u6a21\u56e2\u6218\u4e2d\uff0c\u53c2\u4e0e\u591a\u4eba\u51fb\u6740\u654c\u4eba\uff0c\u95ea\u73b0\u9003\u751f\uff0c\u5b9e\u73b0\u4e09\u8fde\u51b3\u80dc\uff0c\u5948\u65af\uff01',
      barrage: [
        '\u5927\u89c4\u6a21\u56e2\u6218\uff0c\u9189\u5367\u6c99\u573a\u541b\u83ab\u7b11\uff0c\u53e4\u6765\u5f81\u6218\u51e0\u4eba\u56de',
        '\u591a\u6740\uff0c\u6467\u67af\u62c9\u673d',
        '\u95ea\u73b0\u9003\u751f\uff0c\u864e\u53e3\u4f59\u751f',
        '\u4e09\u8fde\u51b3\u80dc\uff0c\u79c0\u79c0\u79c0',
      ],
    },
  ],
]

export const InstrumentResults = [
  {
    Harp:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Harp.wav',
    MusicBox:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_MusicBox.wav',
    Luan:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Luan.wav',
    Trombone:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Trombone.wav',
    AcousticGuitar:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_AcousticGuitar.wav',
    Clarine:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Clarine.wav',
    Cello:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Cello.wav',
    Sax:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Sax.wav',
    Flute:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Flute.wav',
    Pipa:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Pipa.wav',
    GuZheng:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_GuZheng.wav',
    Marimba:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Marimba.wav',
    Dulcimer:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Dulcimer.wav',
    Accordion:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample1/vdc8jeinput_Accordion.wav',
  },
  {
    Harp:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Harp.wav',
    MusicBox:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_MusicBox.wav',
    Luan:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Luan.wav',
    Trombone:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Trombone.wav',
    AcousticGuitar:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_AcousticGuitar.wav',
    Clarine:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Clarine.wav',
    Cello:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Cello.wav',
    Sax:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Sax.wav',
    Flute:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Flute.wav',
    Pipa:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Pipa.wav',
    GuZheng:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_GuZheng.wav',
    Marimba:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Marimba.wav',
    Dulcimer:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Dulcimer.wav',
    Accordion:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample2/vs7uihinput_Accordion.wav',
  },
  {
    Harp:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Harp.wav',
    MusicBox:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_MusicBox.wav',
    Luan:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Luan.wav',
    Trombone:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Trombone.wav',
    AcousticGuitar:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_AcousticGuitar.wav',
    Clarine:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Clarine.wav',
    Cello:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Cello.wav',
    Sax:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Sax.wav',
    Flute:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Flute.wav',
    Pipa:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Pipa.wav',
    GuZheng:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_GuZheng.wav',
    Marimba:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Marimba.wav',
    Dulcimer:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Dulcimer.wav',
    Accordion:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample3/0kxl2cinput_Accordion.wav',
  },
  {
    Harp:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Harp.wav',
    MusicBox:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_MusicBox.wav',
    Luan:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Luan.wav',
    Trombone:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Trombone.wav',
    AcousticGuitar:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_AcousticGuitar.wav',
    Clarine:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Clarine.wav',
    Cello:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Cello.wav',
    Sax:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Sax.wav',
    Flute:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Flute.wav',
    Pipa:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Pipa.wav',
    GuZheng:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_GuZheng.wav',
    Marimba:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Marimba.wav',
    Dulcimer:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Dulcimer.wav',
    Accordion:
      'https://smartcontent-source-1257411467.cos.accelerate.myqcloud.com/assets/instruments/sample4/b2dbcbinput_Accordion.wav',
  },
]

export const VideoDescriptionResults = [
  {
    keywords: {
      大类: '\u5168\u8eab',
      腰型: '\u4e2d\u8170',
      年份季节: '\u79cb\u5b63',
      图案: '\u7eaf\u8272',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u6da4\u7eb6',
      面料: '\u6da4\u7eb6',
      袖长: '\u957f\u8896',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u4fee\u8eab',
      领型: 'V\u9886',
      小类: '\u98ce\u8863',
      廓形: 'X\u578b',
    },
    article: [
      '\u8fd9\u6b3e\u98ce\u8863\u91c7\u7528\u6da4\u7eb6\u9762\u6599\u8bbe\u8ba1\uff0c\u67d4\u8f6f\u8212\u9002\uff0c\u4eb2\u80a4\u900f\u6c14\u3002\u7eaf\u8272\u5e95\u8574\uff0c\u5f70\u663e\u4e2a\u6027\u3002v\u9886\u8bbe\u8ba1\uff0c\u51f8\u663e\u5973\u6027\u8116\u9888\u7ebf\u6761\u7f8e\u3002\u65f6\u5c1a\u957f\u8896\u8bbe\u8ba1\u4fee\u9970\u624b\u81c2\u66f2\u7ebf\uff0c\u663e\u7626\u663e\u9ad8\u3002\u9002\u5408\u901a\u52e4\u7a7f\u7740\u3002',
      '\u65e9\u6625\u4e5f\u9700\u8981\u4e00\u6b3e\u98ce\u8863\u6765\u4e86\uff0c\u901a\u52e4\u98ce\u7684\u5c0f\u59d0\u59d0\u4eec\u4e0d\u8981\u4e0d\u8981\u7684\uff0c\u6027\u4ef7\u6bd4\u8d85\u9ad8\u3002\u91c7\u7528\u4e86\u6da4\u7eb6\u9762\u6599\u7cbe\u88c1\u800c\u6210\uff0c\u4f7f\u5176\u4fdd\u6301\u633a\u62ec\u4e0d\u6613\u53d8\u5f62\u7684\u4f18\u70b9\u3002\u7b80\u6d01\u7684v\u9886\uff0c\u5c06\u8138\u578b\u4fee\u9970\u7684\u6070\u5230\u597d\u5904\u3002\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u65e2\u80fd\u663e\u9732\u51fa\u7ea4\u7ec6\u7684\u624b\u81c2\uff0c\u53c8\u6709\u663e\u7626\u663e\u9ad8\u7684\u6548\u679c\u3002\u5e38\u89c4\u7684h\u578b\u5ed3\u5f62\uff0c\u642d\u914d\u4e0a\u7eaf\u8272\u7684\u8863\u8eab\uff0c\u66f4\u662f\u5c06\u804c\u4e1a\u7684\u6c14\u573a\u70d8\u6258\u51fa\u6765\u3002',
      '\u8fd9\u6b3e\u98ce\u8863\u4e0d\u662f\u70c2\u5927\u8857\u7684\u98ce\u8863\uff0c\u800c\u662f\u4e0d\u518d\u662f\u666e\u901a\u7684\u98ce\u8863\u3002\u6da4\u7eb6\u7684\u6750\u8d28\u770b\u4e0a\u53bb\u624b\u611f\u987a\u6ed1\uff0c\u8d28\u5730\u67d4\u8f6f\uff0c\u5177\u6709\u826f\u597d\u7684\u5f39\u6027\uff0c\u7a7f\u8d77\u6765\u4e5f\u66f4\u52a0\u8212\u9002\u3002\u7eaf\u8272\u7684\u5916\u89c2\uff0c\u5e26\u7740\u51e0\u5206\u9ad8\u7ea7\u611f\u3002v\u9886\u7684\u9886\u53e3\uff0c\u5973\u4eba\u5473\u5341\u8db3\u3002\u957f\u8896\u7684\u7248\u578b\uff0c\u4e0d\u4ec5\u663e\u7626\uff0c\u8fd8\u80fd\u5851\u9020\u4f18\u96c5\u7684\u901a\u52e4\u3002',
      '\u8fd9\u6b3e\u4fee\u8eab\u800c\u901a\u52e4\u7684\u957f\u8896\u98ce\u8863\uff0c\u91c7\u7528\u4e86\u6da4\u7eb6\u7684\u9762\u6599\u5236\u6210\uff0c\u6bd4\u8d77\u5e38\u89c4\u7684\u7eaf\u8272\u98ce\u8863\uff0c\u8fd8\u662f\u6da4\u7eb6\u7684\u9762\u6599\uff0c\u4e0d\u4ec5\u7a7f\u7740\u8212\u9002\uff0c\u800c\u4e14\u8fd8\u80fd\u9732\u51fa\u7ea4\u7ec6\u7684\u624b\u8155\uff0c\u663e\u5f97\u901a\u52e4\u800c\u4f18\u96c5\u3002\u52a0\u4e0av\u9886\u7684\u8bbe\u8ba1\uff0c\u9732\u51fa\u8ff7\u4eba\u7684\u9501\u9aa8\uff0c\u589e\u6dfb\u5973\u4eba\u5473\uff0c\u5438\u775b\u52a8\u4eba\u3002',
      '\u98ce\u8863\u4e00\u76f4\u90fd\u662f\u663e\u7626\u7684\u4ee3\u540d\u8bcd\uff0c\u8fd9\u6b3e\u98ce\u8863\u5728\u8272\u5f69\u7684\u8fd0\u7528\u4e0b\u663e\u5f97\u5f88\u6709\u5c42\u6b21\u611f\u3002\u6da4\u7eb6\u9762\u6599\u624b\u611f\u7ec6\u817b\u987a\u6ed1\uff0c\u5177\u6709\u5f88\u597d\u7684\u5782\u5760\u611f\u548c\u5782\u5760\u611f\u3002\u5e38\u89c4\u7684v\u9886\u8bbe\u8ba1\u5728\u4fee\u9970\u8138\u578b\u7684\u540c\u65f6\uff0c\u4e5f\u66f4\u52a0\u5e72\u7ec3\u5229\u843d\u3002\u57fa\u7840\u7684\u7eaf\u8272\u957f\u8896\u8bbe\u8ba1\uff0c\u65b9\u4fbf\u7a7f\u7740\u3002\u901a\u52e4\u7684\u897f\u88c5\u9886\u8bbe\u8ba1\u663e\u5f97\u5229\u843d\u800c\u5e72\u7ec3\uff0c\u975e\u5e38\u9002\u5408\u901a\u52e4\u7a7f\u7740\u7684\u5973\u6027\u7a7f\u7740\u3002',
    ],
  },
  {
    keywords: {
      大类: '\u5168\u8eab',
      腰型: '\u9ad8\u8170',
      年份季节: '\u79cb\u5b63',
      图案: '\u7eaf\u8272',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u68c9',
      面料: '\u6da4\u7eb6',
      袖长: '\u957f\u8896',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u76f4\u7b52',
      领型: '\u65b9\u9886',
      廓形: 'A\u578b',
      小类: '\u886c\u886b',
    },
    article: [
      '\u7eaf\u8272\u7684\u886c\u886b\uff0c\u7b80\u6d01\u7684\u65b9\u9886\u957f\u8896\uff0c\u9002\u5408\u901a\u52e4\u7a7f\u7740\u3002\u5e38\u89c4\u7684\u65b9\u9886\u8bbe\u8ba1\uff0c\u7b80\u6d01\u5927\u65b9\u3002\u7cbe\u9009\u4f18\u8d28\u7684\u68c9\u6da4\u7eb6\u9762\u6599\uff0c\u624b\u611f\u67d4\u8f6f\u7ec6\u817b\uff0c\u4eb2\u80a4\u900f\u6c14\u3002',
      '\u8fd9\u6b3e\u886c\u886b\u91c7\u7528\u4f18\u8d28\u7684\u6da4\u7eb6\u9762\u6599\uff0c\u8d28\u5730\u67d4\u8f6f\uff0c\u7a7f\u7740\u8212\u9002\u900f\u6c14\u3002\u7eaf\u8272\u7684\u8272\u8c03\uff0c\u663e\u5f97\u66f4\u52a0\u9971\u6ee1\u6709\u578b\u3002\u901a\u52e4\u7684\u57fa\u7840\u6b3e\uff0c\u9002\u5408\u5404\u79cd\u573a\u5408\u7a7f\u7740\u3002\u7b80\u6d01\u7684\u65b9\u9886\u8bbe\u8ba1\uff0c\u7a7f\u7740\u66f4\u663e\u5e72\u7ec3\u3002\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u4e0d\u6311\u8eab\u6750\u3002',
      '\u8fd9\u6b3e\u886c\u886b\u91c7\u7528\u6da4\u7eb6\u53ca\u6da4\u7eb6\u9762\u6599\u5236\u4f5c\uff0c\u8d28\u5730\u67d4\u8f6f\u987a\u6ed1\uff0c\u5177\u6709\u8f83\u597d\u7684\u5782\u6027\uff0c\u7a7f\u7740\u8212\u9002\u4eb2\u80a4\u3002\u5e38\u89c4\u7684\u76f4\u7b52\u7248\u578b\u4e0d\u6311\u8eab\u6750\u3002\u7cbe\u81f4\u7684\u5c0f\u65b9\u9886\uff0c\u81ea\u7136\u7684\u4fee\u9970\u8116\u9888\u66f2\u7ebf\u3002\u7b80\u6d01\u7d20\u51c0\u7eaf\u8272\u4e0d\u5e26\u4e00\u4e1d\u6742\u8d28\uff0c\u4f4e\u8c03\u8010\u770b\u3002\u901a\u52e4\u4e0d\u62d6\u6c93\u3002',
      '\u8fd9\u6b3e\u5e38\u89c4\u6b3e\u7684\u957f\u8896\u886c\u886b\uff0c\u7eaf\u8272\u7684\u68c9\u8d28\u9762\u6599\uff0c\u4e0d\u540c\u989c\u8272\u53ef\u9009\uff0c\u901a\u52e4\u4e5f\u53ef\u7a7f\u3002\u91c7\u7528\u4e86\u6da4\u7eb6\u7684\u6750\u8d28\uff0c\u6da4\u7eb6\u6750\u8d28\uff0c\u624b\u611f\u975e\u5e38\u597d\uff0c\u7a7f\u7740\u8212\u9002\u53c8\u663e\u6c14\u8d28\u3002\u7b80\u6d01\u7684\u5c0f\u65b9\u9886\uff0c\u5b9e\u7a7f\u6027\u5f3a\u3002',
      '\u68c9\u8d28\u9762\u6599\u642d\u914d\u7565\u5fae\u901a\u52e4\u7684\u886c\u886b\uff0c\u663e\u5f97\u5f88\u6709\u5973\u4eba\u5473\uff0c\u7eaf\u8272\u4e0d\u5e26\u4efb\u4f55\u88c5\u9970\uff0c\u663e\u5f97\u66f4\u6709\u7279\u8272\uff0c\u5f70\u663e\u51fa\u9ad8\u7ea7\u7684\u65f6\u5c1a\u54c1\u5473\u3002\u65b9\u9886\u8bbe\u8ba1\uff0c\u7b80\u6d01\u5927\u65b9\u3002\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u80fd\u4ece\u89c6\u89c9\u4e0a\u63d0\u5347\u8170\u7ebf\uff0c\u66f4\u663e\u4fee\u957f\u3002\u6da4\u7eb6\u7684\u9762\u6599\uff0c\u624b\u611f\u987a\u6ed1\uff0c\u5782\u5760\u611f\u5f3a\u3002\u68c9\u8d28\u9762\u6599\uff0c\u7a7f\u7740\u8212\u9002\u4eb2\u80a4\u3002',
    ],
  },
  {
    keywords: {
      大类: '\u5916\u5957',
      腰型: '\u4e2d\u8170',
      年份季节: '\u79cb\u5b63',
      图案: '\u7eaf\u8272',
      衣长: '\u77ed\u6b3e',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u68c9',
      面料: '\u6da4\u7eb6',
      衣门襟: '\u62c9\u94fe',
      袖长: '\u957f\u8896',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u76f4\u7b52',
      领型: '\u5706\u9886',
    },
    article: [
      '\u8fd9\u6b3e\u901a\u52e4\u5e38\u89c4\u7684\u5916\u5957\uff0c\u91c7\u7528\u5e38\u89c4\u7684\u77ed\u6b3e\u8bbe\u8ba1\uff0c\u7a7f\u7740\u8d77\u6765\u4e0d\u4f1a\u663e\u5f97\u539a\u91cd\uff0c\u53cd\u800c\u66f4\u5177\u5b9e\u7a7f\u6027\u3002\u91c7\u7528\u6da4\u7eb6\u9762\u6599\uff0c\u8d28\u611f\u67d4\u8f6f\u7ec6\u817b\u3002\u5b83\u7684\u5706\u9886\u8bbe\u8ba1\uff0c\u5e76\u6ca1\u6709\u8fc7\u591a\u7684\u88c5\u9970\uff0c\u51f8\u663e\u4f18\u96c5\u6c14\u8d28\u3002\u7cbe\u81f4\u7684\u91d1\u5c5e\u62c9\u94fe\u8bbe\u8ba1\uff0c\u51f8\u663e\u8d28\u611f\u3002\u957f\u8896\u7684\u70b9\u775b\u4e4b\u7b14\u3002\u7d20\u96c5\u7684\u7eaf\u8272\u8bbe\u8ba1\uff0c\u8ba9\u8fd9\u6b3e\u4e0a\u8863\u5145\u6ee1\u9ad8\u7ea7\u611f\u3002\u77ed\u6b3e\u7248\u578b\u5851\u9020\u5927\u957f\u817f\u3002',
      '\u8fd9\u6b3e\u5e38\u89c4\u6b3e\u5f0f\u7684\u957f\u8896\u77ed\u6b3e\u5916\u5957\uff0c\u8863\u8eab\u91c7\u7528100%\u6da4\u7eb6\u9762\u6599\uff0c\u8d28\u5730\u67d4\u548c\uff0c\u7a7f\u7740\u8212\u9002\u4eb2\u80a4\uff0c\u5728\u7eaf\u8272\u8863\u8eab\u7684\u886c\u6258\u4e0b\uff0c\u4e5f\u589e\u6dfb\u4e86\u4e00\u4efd\u901a\u52e4\u6c14\u8d28\u3002\u7ecf\u5178\u7684\u5706\u9886\u8bbe\u8ba1\uff0c\u5e26\u6765\u8212\u9002\u7684\u7a7f\u7740\u4f53\u9a8c\uff0c\u8863\u8eab\u4e0a\u6c34\u6ef4\u5f62\u7684\u91d1\u5c5e\u62c9\u94fe\uff0c\u4e5f\u662f\u4e00\u5927\u4eae\u70b9\uff0c\u5c0f\u5de7\u7cbe\u81f4\u3002\u77ed\u6b3e\u7684\u957f\u5ea6\uff0c\u642d\u914d\u77ed\u6b3e\u7684\u7248\u578b\uff0c\u80fd\u591f\u5f88\u597d\u7684\u62c9\u957f\u8eab\u4f53\u6bd4\u4f8b\u3002',
      '\u8fd9\u6b3e\u7eaf\u8272\u77ed\u6b3e\u5916\u5957\uff0c\u6da4\u7eb6\u9762\u6599\uff0c\u8d28\u5730\u67d4\u987a\uff0c\u7ed9\u4eba\u65e0\u6bd4\u6e29\u6696\u7684\u5475\u62a4\u3002\u5706\u9886\u957f\u8896\uff0c\u5e38\u89c4\u8584\u539a\u9002\u4e2d\uff0c\u624b\u611f\u67d4\u8f6f\u7ec6\u817b\uff0c\u7eb9\u8def\u6e05\u6670\uff0c\u5e38\u89c4\u7684\u957f\u8896\uff0c\u642d\u914d\u8d77\u6765\u66f4\u6709\u6863\u6b21\uff0c\u5927\u65b9\u5f97\u4f53\uff0c\u65f6\u5c1a\u767e\u642d\u3002\u77ed\u6b3e\u7248\u578b\uff0c\u62c9\u957f\u8eab\u6750\u6bd4\u4f8b\uff0c\u901a\u52e4\u4f18\u96c5\u4e24\u4e0d\u8bef\u3002',
      '\u8fd9\u6b3e\u6765\u81eaBRAND\u7684\u7537\u58eb\u5916\u5957\uff0c\u91c7\u7528\u6da4\u7eb6\u9762\u6599\u5236\u4f5c\u800c\u6210\uff0c\u624b\u611f\u67d4\u8f6f\uff0c\u7a7f\u7740\u8212\u9002\u6709\u578b\uff1b\u5e38\u89c4\u7684\u5e38\u89c4\u6b3e\u8bbe\u8ba1\uff1b\u5de6\u8fb9\u8863\u95e8\u895f\u62c9\u94fe\uff0c\u7a7f\u8131\u65b9\u4fbf\uff0c\u7eaf\u8272\u4e5f\u4e0d\u6311\u4eba\uff1b\u5706\u9886\u7684\u8bbe\u8ba1\uff0c\u901a\u52e4\u4e5f\u662f\u5341\u5206\u7684\u6709\u6c14\u8d28\uff1b\u77ed\u6b3e\u7684\u9020\u578b\uff0c\u51f8\u663e\u4f60\u7684\u4f18\u96c5\u6c14\u8d28\uff0c\u77ed\u6b3e\u7684\u7248\u578b\u8bbe\u8ba1\uff0c\u5f88\u597d\u7684\u62c9\u957f\u8eab\u7ebf\uff0c\u8ba9\u4f60\u770b\u8d77\u6765\u66f4\u9ad8\u6311\u3002',
      '\u7eaf\u8272\u7684\u77ed\u6b3e\u5916\u5957\uff0c\u65e0\u8bba\u662f\u65e5\u5e38\u901a\u52e4\u8fd8\u662f\u65e5\u5e38\u7a7f\u642d\uff0c\u90fd\u80fd\u8f7b\u677ehold\u4f4f\u3002\u62c9\u94fe\u95e8\u895f\u7684\u8bbe\u8ba1\u3002\u62c9\u94fe\u7684\u95e8\u895f\u3002\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u65b9\u4fbf\u7a7f\u8131\u3002\u77ed\u6b3e\u7684\u8bbe\u8ba1\uff0c\u5f88\u597d\u7684\u4fee\u9970\u8eab\u6750\u6bd4\u4f8b\u3002\u7cbe\u9009\u4f18\u8d28\u7684\u68c9\u6c28\u6da4\u7eb6\u9762\u6599\uff0c\u624b\u611f\u67d4\u8f6f\u7ec6\u817b\uff0c\u4eb2\u80a4\u900f\u6c14\uff0c\u9002\u5408\u590f\u5b63\u7a7f\u7740\u3002',
    ],
  },
  {
    keywords: {
      大类: '\u88e4\u5b50',
      腰型: '\u4e2d\u8170',
      年份季节: '\u51ac\u5b63',
      图案: '\u7eaf\u8272',
      裤长: '\u957f\u88e4',
      风格: '\u901a\u52e4',
      款式: '\u5957\u5934',
      厚薄: '\u5e38\u89c4',
      材质: '\u6da4\u7eb6',
      面料: '\u6da4\u7eb6',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u76f4\u7b52',
      小类: '\u94c5\u7b14\u88d9',
      袖长: '\u957f\u8896',
    },
    article: [
      '\u7eaf\u8272\u5957\u5934\u7eaf\u8272\u5957\u5934\u7684\u8bbe\u8ba1\uff0c\u7eaf\u8272\u5957\u5934\u7684\u8bbe\u8ba1\uff0c\u901a\u52e4\u7a7f\u7740\u3002\u5957\u5934\u5f0f\u7684\u8bbe\u8ba1\uff0c\u7a7f\u8131\u65b9\u4fbf\u3002\u7b80\u6d01\u7684\u5957\u5934\u8bbe\u8ba1\uff0c\u65b9\u4fbf\u7a7f\u8131\u3002\u6da4\u7eb6\u9762\u6599\uff0c\u624b\u611f\u67d4\u8f6f\u7ec6\u817b\uff0c\u8d34\u8eab\u7a7f\u642d\u4e5f\u5f88\u8212\u9002\u3002',
      '\u7eaf\u8272\u5957\u5934\u7eaf\u8272\u5957\u5934\u7684\u8bbe\u8ba1\uff0c\u7eaf\u8272\u5957\u5934\u7684\u8bbe\u8ba1\uff0c\u901a\u52e4\u7a7f\u7740\u3002\u5957\u5934\u5f0f\u7684\u8bbe\u8ba1\uff0c\u7a7f\u8131\u65b9\u4fbf\u3002\u7b80\u6d01\u7684\u5957\u5934\u8bbe\u8ba1\uff0c\u65b9\u4fbf\u7a7f\u8131\u3002\u6da4\u7eb6\u9762\u6599\uff0c\u624b\u611f\u67d4\u8f6f\u7ec6\u817b\uff0c\u8d34\u8eab\u7a7f\u4e5f\u5f88\u8212\u9002\u3002',
      '\u5e38\u89c4\u6b3e\u7684\u5957\u5934\u957f\u8896\u76f4\u7b52\u94c5\u7b14\u88d9\u7248\u578b\uff0c\u7ed3\u5408\u6da4\u7eb6\u53ca\u6da4\u7eb6\u7684\u9762\u6599\uff0c\u4fee\u9970\u51fa\u7cbe\u81f4\u7684\u811a\u8e1d\u66f2\u7ebf\u3002\u8d34\u5408\u8eab\u4f53\u7684\u4e2d\u8170\u526a\u88c1\uff0c\u524d\u90e8\u4e2d\u8170\u6536\u7701\uff0c\u81ea\u7136\u8d34\u5408\u8170\u90e8\u66f2\u7ebf\u3002\u4ee5\u7eaf\u8272\u7684\u5916\u89c2\u5448\u73b0\uff0c\u7ed9\u4eba\u5e72\u51c0\u7eaf\u7cb9\u7684\u65f6\u9ae6\u611f\u3002',
      '\u7ecf\u5178\u7684\u7eaf\u8272\u4f11\u95f2\u88e4\uff0c\u7cbe\u9009\u4f18\u8d28\u6da4\u7eb6\u9762\u6599\uff0c\u8d28\u611f\u633a\u62ec\u6709\u578b\u3002\u94c5\u7b14\u88d9\u7684\u8bbe\u8ba1\u505a\u5de5\uff0c\u5728\u7ec6\u8282\u4e2d\u51f8\u663e\u54c1\u8d28\u3002\u5e38\u89c4\u7684\u957f\u8896\uff0c\u901a\u52e4\u53c8\u5f88\u6709\u8303\u3002\u4e2d\u8170\u7684\u8bbe\u8ba1\uff0c\u7a7f\u7740\u8212\u9002\u4e0d\u7d27\u7ef7\u3002\u5e38\u89c4\u7684\u76f4\u7b52\u7248\u578b\uff0c\u6ca1\u6709\u675f\u7f1a\u611f\uff0c\u8ba9\u4eba\u773c\u524d\u4e00\u4eae\uff0c\u5957\u5934\u7a7f\u8131\u4e5f\u5f88\u65b9\u4fbf\u3002',
      '\u7eaf\u8272\u6da4\u7eb6\u9762\u6599\u5236\u4f5c\uff0c\u624b\u611f\u8212\u9002\u67d4\u548c\uff0c\u901a\u52e4\u767e\u642d\uff0c\u540c\u65f6\u53c8\u6613\u642d\u914d\u3002\u5957\u5934\u7684\u8bbe\u8ba1\uff0c\u4e0d\u6311\u8eab\u6750\uff0c\u7a7f\u8131\u65b9\u4fbf\u3002\u5e38\u89c4\u7684\u76f4\u7b52\u957f\u8896\uff0c\u76f4\u7b52\u7684\u7248\u578b\uff0c\u9002\u5f53\u4fee\u9970\u4e86\u8eab\u6750\u6bd4\u4f8b\u3002\u4e2d\u8170\u7684\u8bbe\u8ba1\uff0c\u91c7\u7528\u4e2d\u8170\u7684\u9ad8\u5ea6\uff0c\u7a7f\u7740\u8212\u9002\u4e0d\u7d27\u7ef7\u3002',
    ],
  },
]

export const ImageDescriptionResults = [
  {
    keywords: {
      大类: '\u5916\u5957',
      腰型: '\u4e2d\u8170',
      年份季节: '\u79cb\u5b63',
      图案: '\u7eaf\u8272',
      衣长: '\u4e2d\u957f\u6b3e',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u9526\u7eb6',
      面料: '\u68c9\u5e03',
      衣门襟: '\u5355\u6392\u6263',
      袖长: '\u957f\u8896',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u76f4\u7b52',
      领型: '\u7acb\u9886',
      小类: '\u88e4\u5b50',
    },
    article: [
      '\u54e5\u5f1f\u7684\u4e2d\u957f\u6b3e\u5916\u5957\uff0c\u5bbd\u677e\u7684\u7248\u578b\uff0c\u4e0d\u4ec5\u80fd\u591f\u4fee\u9970\u8eab\u6750\uff0c\u7a7f\u7740\u66f4\u663e\u901a\u52e4\u5e72\u7ec3\u3002\u522b\u81f4\u7684\u7acb\u9886\u8bbe\u8ba1\uff0c\u642d\u914d\u540c\u8272\u7684\u5355\u6392\u6263\uff0c\u66f4\u662f\u4e3a\u6574\u4f53\u589e\u6dfb\u4e86\u4e0d\u5c11\u7f8e\u611f\uff0c\u7eaf\u8272\u7684\u8bbe\u8ba1\uff0c\u66f4\u662f\u80fd\u591f\u63d0\u5347\u6c14\u8d28\u3002\u5e38\u89c4\u7684\u957f\u8896\uff0c\u5bf9\u8eab\u6750\u7684\u5305\u5bb9\u6027\u66f4\u5927\uff0c\u7a7f\u7740\u8212\u9002\u3002',
      '\u7eaf\u8272\u82ce\u9ebb\u5236\u4f5c\u7684\u5916\u5957\uff0c\u5728\u901a\u52e4\u98ce\u4e5f\u662f\u975e\u5e38\u7684\u767e\u642d\u4e0d\u6311\u4eba\u3002\u8fd9\u6b3e\u5e38\u89c4\u4e2d\u957f\u6b3e\u7acb\u9886\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u5728\u5e38\u89c4\u7684\u88c1\u526a\u548c\u5bbd\u677e\u7248\u578b\u7684\u6f14\u7ece\u4e2d\uff0c\u4e5f\u91c7\u7528\u4e86\u5355\u6392\u6263\u7acb\u9886\u548c\u5355\u6392\u6263\u7684\u88c5\u9970\uff0c\u7a7f\u7740\u5341\u5206\u7684\u65f6\u9ae6\u663e\u6c14\u8d28\u3002',
      '\u8bbe\u8ba1\u611f\u5f88\u5f3a\u7684\u4e00\u6b3e\u4e2d\u957f\u6b3e\u5916\u5957\uff0c\u5bbd\u677e\u7684\u7248\u578b\u4e0d\u6311\u4eba\u7a7f\uff0c\u7eaf\u8272\u7684\u8bbe\u8ba1\u4e0e\u7acb\u9886\u7684\u642d\u914d\u4e5f\u662f\u5f88\u597d\u7684\uff0c\u6bd4\u5e38\u89c4\u7684\u957f\u8896\u5916\u5957\u591a\u4e86\u51e0\u5206\u901a\u52e4\u6c14\u8d28\u3002\u8863\u95e8\u895f\u91c7\u7528\u5355\u6392\u6263\u8bbe\u8ba1\uff0c\u65b9\u4fbf\u7a7f\u8131\u3002\u5e38\u89c4\u7684\u8896\u5b50\u8bbe\u8ba1\uff0c\u5e38\u89c4\u7684\u957f\u5ea6\uff0c\u4f18\u96c5\u968f\u6027\u3002',
      '\u6625\u5929\u662f\u7a7f\u5916\u5957\u7684\u5b63\u8282\uff0c\u53ef\u4ee5\u8bf4\u662f\u5e05\u6c14\u901a\u52e4\u98ce\u4e86\u3002BRAND\u8fd9\u6b3e\u5916\u5957\uff0c\u6574\u4f53\u91c7\u7528\u4e86\u5e38\u89c4\u504f\u5bbd\u677e\u7684\u7248\u578b\u526a\u88c1\uff0c\u7a7f\u51fa\u6175\u61d2\u968f\u6027\u4e4b\u611f\u3002\u800c\u5355\u6392\u6263\u7684\u8bbe\u8ba1\uff0c\u4e5f\u65b9\u4fbf\u65e5\u5e38\u7684\u7a7f\u642d\uff0c\u7eaf\u8272\u7684\u8863\u8eab\u66f4\u5177\u5c42\u6b21\u611f\u548c\u7f8e\u89c2\u6027\u3002\u7acb\u9886\u7684\u8bbe\u8ba1\uff0c\u603b\u662f\u80fd\u5f70\u663e\u51fa\u7b80\u7ec3\u7684\u611f\u89c9\u3002\u800c\u4e2d\u957f\u6b3e\u7684\u76f4\u7b52\u7248\u578b\uff0c\u8fd8\u6709\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u5c55\u73b0\u51fa\u98d8\u9038\u7684\u611f\u89c9\u3002',
      '\u8fd9\u6b3e\u7eaf\u8272\u7684\u4e2d\u957f\u6b3e\u5916\u5957\uff0c\u5bbd\u677e\u7684\u7248\u578b\uff0c\u5bf9\u8eab\u6750\u7684\u5305\u5bb9\u6027\u5f88\u597d\u3002\u7acb\u9886\u7684\u8bbe\u8ba1\uff0c\u7b80\u6d01\u5927\u65b9\uff0c\u5355\u6392\u6263\u7684\u8bbe\u8ba1\uff0c\u5e38\u89c4\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u901a\u52e4\u767e\u642d\u3002\u4e2d\u957f\u7684\u7248\u578b\uff0c\u5f88\u597d\u7684\u4fee\u9970\u4e86\u8eab\u6750\u6bd4\u4f8b\uff0c\u8ba9\u4f60\u8f7b\u677e\u7a7f\u51fa\u9ad8\u6311\u7684\u8eab\u6750\u3002',
    ],
  },
  {
    keywords: {
      大类: '\u5185\u642d',
      腰型: '\u81ea\u7136\u8170',
      年份季节: '\u79cb\u5b63',
      图案: '\u7eaf\u8272',
      衣长: '\u5e38\u89c4\u6b3e',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u6da4\u7eb6',
      面料: '\u6da4\u7eb6',
      衣门襟: '\u5957\u5934',
      袖长: '\u65e0\u8896',
      适用年龄: '18-24\u5468\u5c81',
      服装版型: '\u5bbd\u677e',
      领型: '\u8fde\u5e3d',
      小类: '\u5939\u514b',
    },
    article: [
      '\u6da4\u7eb6\u9762\u6599\u67d4\u8f6f\u7ec6\u817b\uff0c\u5177\u6709\u4e0d\u9519\u7684\u4fdd\u6696\u6548\u679c\uff0c\u5960\u5b9a\u4e86\u5973\u6027\u7684\u6e29\u67d4\u6c14\u606f\u3002\u7eaf\u8272\u7b80\u6d01\u5927\u6c14\uff0c\u6700\u80fd\u51f8\u663e\u8be5\u6709\u7684\u9ad8\u7ea7\u611f\u3002\u901a\u52e4\u4f46\u4e0d\u5938\u5f20\u5f88\u6709\u8d28\u611f\uff0c\u7acb\u4f53\u7684\u526a\u88c1\u8ba9\u8fd9\u6b3e\u4e0a\u8863\u77ac\u95f4\u6253\u52a8\u4e86\u4f60\u7684\u5fc3\u3002\u5706\u9886\u8bbe\u8ba1\u7b26\u5408\u73b0\u4ee3\u5973\u6027\u7684\u804c\u4e1a\u6c14\u8d28\u3002\u7ecf\u5178\u5e38\u89c4\u7684\u5957\u5934\u8bbe\u8ba1\uff0c\u7a7f\u8131\u65b9\u4fbf\uff0c\u4e0d\u663e\u81c3\u80bf\u3002',
    ],
  },
  {
    keywords: {
      大类: '\u5916\u5957',
      腰型: '\u81ea\u7136\u8170',
      年份季节: '\u51ac\u5b63',
      图案: '\u7eaf\u8272',
      衣长: '\u4e2d\u957f\u6b3e',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u6da4\u7eb6',
      面料: '\u6da4\u7eb6',
      衣门襟: '\u5355\u6392\u6263',
      袖长: '\u957f\u8896',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u5bbd\u677e',
      领型: '\u7ffb\u9886',
      小类: '\u5939\u514b',
    },
    article: [
      '\u4e2d\u957f\u6b3e\u7684\u98ce\u8863\u5916\u5957\uff0c\u6ca1\u6709\u592a\u591a\u7684\u675f\u7f1a\u611f\uff0c\u91c7\u7528\u6da4\u7eb6\u7684\u9762\u6599\uff0c\u589e\u52a0\u4e86\u5c42\u6b21\u611f\u548c\u8bbe\u8ba1\u611f\uff0c\u8ba9\u4eba\u773c\u524d\u4e00\u4eae\uff0c\u4ece\u89c6\u89c9\u4e0a\u6253\u9020\u9ec4\u91d1\u6bd4\u4f8b\u7684\u8eab\u6750\u3002\u7b80\u6d01\u7684\u7eaf\u8272\u8863\u8eab\uff0c\u7b80\u6d01\u5927\u65b9\u53c8\u4e0d\u5931\u5973\u4eba\u5473\u3002\u4e2d\u957f\u6b3e\u5f0f\u7684\u7ffb\u9886\uff0c\u5355\u6392\u6263\u88c5\u9970\uff0c\u589e\u52a0\u901a\u52e4\u98ce\uff0c\u4e5f\u53ef\u4ee5\u655e\u5f00\u7a7f\u7740\u3002',
      '\u8fd9\u6b3e\u7eaf\u8272\u7684\u98ce\u8863\u5916\u5957\uff0c\u4e2d\u957f\u6b3e\u7684\u7248\u578b\uff0c\u5bf9\u8eab\u6750\u6709\u5f88\u597d\u7684\u5305\u5bb9\u6027\u3002\u7ecf\u5178\u7684\u7ffb\u9886\u548c\u5355\u6392\u6263\u7684\u8bbe\u8ba1\uff0c\u5f70\u663e\u51fa\u5e72\u7ec3\u901a\u52e4\u7684\u6c14\u8d28\u3002\u5e38\u89c4\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u53ef\u4ee5\u5f88\u597d\u7684\u4fee\u9970\u624b\u81c2\u7684\u66f2\u7ebf\uff0c\u8ba9\u7a7f\u7740\u66f4\u52a0\u7684\u8212\u9002\u81ea\u5728\u3002\u7ecf\u5178\u7684\u5c0f\u7ffb\u9886\uff0c\u5355\u6392\u6263\u7684\u95e8\u895f\uff0c\u7a7f\u8131\u65b9\u4fbf\u3002',
      '\u8fd9\u6b3e\u4e2d\u957f\u6b3e\u98ce\u8863\u5916\u5957\uff0c\u91c7\u7528\u4e86\u6da4\u7eb6\u9762\u6599\u7684\u8bbe\u8ba1\uff0c\u901a\u52e4\u611f\u5341\u8db3\u3002\u7ffb\u9886\u7684\u9886\u53e3\uff0c\u6709\u5f88\u6709\u8d28\u611f\u7684\u611f\u89c9\u3002\u957f\u8896\u7684\u8bbe\u8ba1\uff0c\u5e26\u6709\u5355\u6392\u6263\u7684\u88c5\u9970\uff0c\u53c8\u663e\u5f97\u6709\u5c42\u6b21\u611f\u3002\u7eaf\u8272\u51c0\u9762\u7684\u7248\u578b\uff0c\u8ba9\u4eba\u773c\u524d\u4e00\u4eae\u3002',
      '\u901a\u52e4\u6b3e\u7684\u98ce\u8863\u5916\u5957\uff0c\u9009\u7528\u6da4\u7eb6\u9762\u6599\u5236\u4f5c\u800c\u6210\uff0c\u5728\u7eaf\u8272\u7684\u8863\u8eab\u5916\u9762\uff0c\u6709\u7740\u5fae\u5fae\u7684\u6da4\u7eb6\u6750\u8d28\uff0c\u89e6\u611f\u8212\u9002\uff0c\u4e0a\u8eab\u633a\u62ec\u6709\u578b\u3002\u5e38\u89c4\u7684\u7ffb\u9886\u8bbe\u8ba1\uff0c\u5f70\u663e\u4f18\u96c5\u6c14\u8d28\u3002\u98d8\u9038\u7684\u957f\u8896\uff0c\u52a0\u4e0a\u5355\u6392\u6263\u7684\u70b9\u7f00\uff0c\u66f4\u6dfb\u4fcf\u76ae\u611f\u3002\u4e2d\u957f\u6b3e\u7684\u76f4\u7b52\u7248\u578b\uff0c\u4fee\u9970\u817f\u578b\u7684\u540c\u65f6\uff0c\u5e26\u6765\u59a5\u59a5\u7684\u65f6\u5c1a\u6c14\u8d28\u3002',
      '\u8fd9\u6b3e\u4e2d\u957f\u6b3e\u7684\u98ce\u8863\u5916\u5957\uff0c\u91c7\u7528\u4e86\u6da4\u7eb6\u7684\u9762\u6599\uff0c\u5176\u5728\u633a\u62ec\u7684\u7248\u578b\u4e4b\u4e0a\u3002\u7eaf\u8272\u7684\u8863\u8eab\uff0c\u8ba9\u4eba\u773c\u524d\u4e00\u4eae\u3002\u53ef\u4ee5\u901a\u52e4\u7684\u901a\u52e4\u98ce\u8303\uff0c\u4e5f\u80fd\u8f7b\u677ehold\u4f4f\u6574\u4f53\u9020\u578b\u3002\u7ecf\u5178\u7684\u7ffb\u9886\u914d\u5408\u5355\u6392\u6263\u95e8\u895f\uff0c\u65b9\u4fbf\u4e86\u7a7f\u8131\u3002\u5c0f\u5fc3\u673a\u7684\u8759\u8760\u957f\u8896\u8bbe\u8ba1\uff0c\u5c06\u8863\u6446\u9690\u85cf\u8d77\u6765\uff0c\u51f8\u663e\u7ea4\u7ec6\u7684\u624b\u81c2\u3002',
    ],
  },
  {
    keywords: {
      大类: '\u5916\u5957',
      腰型: '\u81ea\u7136\u8170',
      年份季节: '\u51ac\u5b63',
      图案: '\u6761\u7eb9',
      衣长: '\u77ed\u6b3e',
      风格: '\u901a\u52e4',
      厚薄: '\u5e38\u89c4',
      材质: '\u9526\u7eb6',
      面料: '\u6da4\u7eb6',
      衣门襟: '\u5355\u6392\u6263',
      袖长: '\u77ed\u8896',
      适用年龄: '25-34\u5468\u5c81',
      服装版型: '\u4fee\u8eab',
      领型: 'V\u9886',
      小类: '\u5939\u514b',
    },
    article: [
      '\u8fd9\u6b3e\u5e38\u89c4\u6b3e\u5f0f\u7684\u77ed\u6b3e\u4fee\u8eab\u77ed\u6b3e\u5916\u5957\uff0c\u91c7\u7528\u6da4\u7eb6\u9762\u6599\u5236\u4f5c\u800c\u6210\uff0c\u8d34\u8eab\u7a7f\u7740\u66f4\u52a0\u663e\u7626\uff0c\u77ed\u6b3e\u7684\u957f\u5ea6\u4e5f\u9002\u5408\u901a\u52e4\u7a7f\u7740\u3002\u7ecf\u5178\u7684v\u9886\u8bbe\u8ba1\uff0c\u6070\u5230\u597d\u5904\u7684\u9732\u51fa\u9501\u9aa8\u7684\u7f8e\u611f\u3002\u7b80\u6d01\u5927\u65b9\u7684\u77ed\u8896\u8bbe\u8ba1\uff0c\u642d\u914d\u4e0a\u91d1\u5c5e\u5355\u6392\u6263\u88c5\u9970\uff0c\u6253\u7834\u7eaf\u8272\u7684\u5355\u8c03\uff0c\u53c8\u4e0d\u5931\u4f18\u96c5\u6c14\u8d28\u3002',
      '\u901a\u52e4\u5e38\u89c4\u7684\u77ed\u8896\u5916\u5957\uff0c\u91c7\u7528\u72ec\u7279\u7684\u6da4\u7eb6\u6750\u8d28\uff0c\u4fee\u8eab\u53c8\u663e\u7626\u3002\u6df1v\u9886\u7684\u8bbe\u8ba1\uff0c\u5728\u89c6\u89c9\u4e0a\u66f4\u662f\u9884\u9632\u8d70\u5149\u3002\u6781\u7b80\u7684\u5355\u6392\u6263\u70b9\u7f00\u5176\u4e2d\uff0c\u6253\u7834\u7eaf\u8272\u8863\u8eab\u7684\u5355\u8c03\u611f\u3002\u77ed\u6b3e\u7248\u578b\uff0c\u62c9\u957f\u8170\u7ebf\uff0c\u4f18\u5316\u8eab\u6750\u6bd4\u4f8b\uff0c\u66f4\u663e\u9ad8\u6311\uff0c\u5c3d\u663e\u5973\u6027\u5e72\u7ec3\u6c14\u8d28\u3002',
      '\u77ed\u6b3e\u4fee\u8eab\u7684\u77ed\u6b3e\u5916\u5957\uff0c\u65f6\u5c1a\u5229\u843d\u7684\u77ed\u6b3e\u7248\u578b\uff0c\u4e0d\u4ec5\u7a7f\u7740\u8212\u9002\uff0c\u66f4\u9002\u5e94\u4e86\u5973\u6027\u7684\u901a\u52e4\u9700\u6c42\u3002\u65f6\u9ae6v\u9886\uff0c\u51f8\u663e\u5973\u6027\u7684\u67d4\u7f8e\u6c14\u8d28\uff0c\u66f4\u6709\u5438\u775b\u4eae\u70b9\u3002\u95e8\u895f\u5355\u6392\u6263\u5f00\u5408\uff0c\u7a7f\u8131\u65b9\u4fbf\u3002\u8212\u9002\u7684\u6da4\u7eb6\u9762\u6599\u4e0e\u5e38\u89c4\u7684\u77ed\u8896\u88c1\u526a\uff0c\u4e5f\u80fd\u80dc\u4efb\u65e5\u5e38\u7684\u72ec\u7acb\u642d\u914d\u3002',
      'BRAND\u5e26\u6765\u7684\u8fd9\u6b3e\u5916\u5957\uff0c\u91c7\u7528\u6da4\u7eb6\u6750\u8d28\u4e3a\u57fa\u7840\uff0c\u4f7f\u5176\u7a7f\u7740\u8212\u9002\u4eb2\u80a4\uff1b\u800c\u67d4\u548c\u7684\u7eaf\u8272\u8272\u8c03\uff0c\u65e2\u6253\u7834\u4e86\u7eaf\u8272\u8c03\u7684\u5355\u8c03\u611f\uff0c\u53c8\u5e26\u6765\u4e86\u4e00\u4e1d\u6210\u719f\u7a33\u91cd\u7684\u611f\u89c9\uff1b\u77ed\u6b3e\u4fee\u8eab\u7684\u7248\u578b\uff0c\u642d\u914d\u77ed\u6b3e\u7684\u9020\u578b\uff0c\u5c06\u5973\u6027\u7684\u66fc\u5999\u8eab\u59ff\u548c\u65e5\u5e38\u901a\u52e4\u7684\u4e00\u9762\u8fdb\u884c\u4e86\u6539\u826f\uff1b\u7ecf\u5178\u7684v\u9886\u548c\u5355\u6392\u6263\u7684\u7ed3\u5408\uff0c\u5219\u6253\u7834\u4e86\u5e38\u89c4\u7684\u8bbe\u8ba1\uff0c\u8d77\u5230\u4e86\u88c5\u9970\u7684\u4f5c\u7528\u3002',
      '\u8fd9\u6b3e\u7eaf\u8272\u77ed\u6b3e\u5916\u5957\uff0c\u91c7\u7528\u4f18\u8d28\u7684\u6da4\u7eb6\u9762\u6599\u5236\u4f5c\uff0c\u7a7f\u7740\u67d4\u8f6f\u8212\u9002\u3002v\u9886\u7684\u8bbe\u8ba1\uff0c\u4f18\u96c5\u5927\u65b9\u3002\u5355\u6392\u6263\u7684\u95e8\u895f\u8bbe\u8ba1\uff0c\u65b9\u4fbf\u7a7f\u8131\u3002\u77ed\u8896\u7684\u8bbe\u8ba1\uff0c\u7a7f\u7740\u66f4\u52a0\u7684\u8212\u9002\u81ea\u5728\u3002\u7ecf\u5178\u7684v\u9886\u8bbe\u8ba1\uff0c\u4fee\u9970\u8116\u9888\u7684\u66f2\u7ebf\u3002\u5e38\u89c4\u901a\u52e4\u7684\u7248\u578b\uff0c\u9002\u5408\u5404\u79cd\u8eab\u6750\u7a7f\u7740\u3002\u4fee\u8eab\u7684\u7248\u578b\u3002',
    ],
  },
]

export const AudioTaggerResults = [
  [
    {
      tagType: "game_ad_mood",
      tag: "轻快",
      probility: "0.1983492523431778",
    },
    {
      tagType: "game_ad_strength",
      tag: "强",
      probility: "0.9232717752456665"
    },
    {
      tagType: "game_ad_genre",
      tag: "电子",
      probility: "0.9709121584892273",
    }
  ],
  [
    {
      tagType: "game_ad_mood",
      tag: "恐怖",
      probility: "0.956697940826416",
    },
    {
      tagType: "game_ad_strength",
      tag: "强",
      probility: "0.9812937378883362",
    },
    {
      tagType: "game_ad_genre",
      tag: "史诗",
      probility: "0.9619895815849304"
    }
  ],
  [
    {
      tagType: "game_ad_mood",
      tag: "悲伤",
      probility: "0.4405220150947571",
    },
    {
      tagType: "game_ad_strength",
      tag: "中",
      probility: "0.988643229007721",
    },
    {
      tagType: "game_ad_genre",
      tag: "仙侠玄幻",
      probility: "0.9629619717597961"
    }
  ],
  [
    {
      tagType: "game_ad_mood",
      tag: "安静",
      probility: "0.7847247123718262",
    },
    {
      tagType: "game_ad_strength",
      tag: "中",
      probility: "0.7440571784973145",
    },
    {
      tagType: "game_ad_genre",
      tag: "流行",
      probility: "0.8254172205924988"
    }
  ]
]