export const resultJson1 =`[
    {
        "class": "chair",
        "box": [
            518.5505,
            78.9696,
            799.7687,
            251.4735
        ],
        "score": 0.989
    },
    {
        "class": "cup",
        "box": [
            431.4066,
            277.5686,
            686.8904,
            454.1647
        ],
        "score": 0.9888
    },
    {
        "class": "dining table",
        "box": [
            0.0,
            222.0689,
            797.9321,
            568.0848
        ],
        "score": 0.9837
    },
    {
        "class": "spoon",
        "box": [
            483.6286,
            391.8563,
            717.0266,
            468.4075
        ],
        "score": 0.9767
    }
]`

export const resultJson2 =`[
    {
        "class": "person",
        "box": [
            42.5378,
            293.0087,
            437.0698,
            727.4437
        ],
        "score": 0.9987
    },
    {
        "class": "person",
        "box": [
            856.9778,
            293.7812,
            1173.9835,
            639.9827
        ],
        "score": 0.9984
    },
    {
        "class": "person",
        "box": [
            381.4131,
            102.7175,
            613.9995,
            652.9283
        ],
        "score": 0.9972
    },
    {
        "class": "person",
        "box": [
            671.974,
            71.3998,
            925.382,
            662.0091
        ],
        "score": 0.9971
    },
    {
        "class": "person",
        "box": [
            462.5752,
            323.174,
            787.4453,
            676.7104
        ],
        "score": 0.9971
    },
    {
        "class": "cup",
        "box": [
            886.1078,
            642.0176,
            953.6769,
            750.845
        ],
        "score": 0.995
    },
    {
        "class": "laptop",
        "box": [
            97.042,
            586.727,
            374.8212,
            780.3599
        ],
        "score": 0.99
    },
    {
        "class": "scissors",
        "box": [
            1182.9457,
            621.5542,
            1245.8706,
            695.0829
        ],
        "score": 0.9748
    },
    {
        "class": "laptop",
        "box": [
            943.8076,
            562.558,
            1207.681,
            724.8631
        ],
        "score": 0.9732
    },
    {
        "class": "book",
        "box": [
            476.1946,
            752.5308,
            728.3314,
            848.1555
        ],
        "score": 0.9066
    },
    {
        "class": "scissors",
        "box": [
            1202.7224,
            622.6173,
            1245.0767,
            668.5786
        ],
        "score": 0.8306
    },
    {
        "class": "potted plant",
        "box": [
            685.7178,
            5.3729,
            1099.8264,
            286.0059
        ],
        "score": 0.7949
    },
    {
        "class": "tv",
        "box": [
            1035.3348,
            345.5078,
            1117.2277,
            450.7619
        ],
        "score": 0.7826
    },
    {
        "class": "dining table",
        "box": [
            0.0,
            273.481,
            231.9687,
            325.0509
        ],
        "score": 0.7544
    },
    {
        "class": "potted plant",
        "box": [
            639.4241,
            183.8295,
            770.7582,
            371.0916
        ],
        "score": 0.7506
    },
    {
        "class": "book",
        "box": [
            539.3278,
            668.0228,
            708.0803,
            733.0292
        ],
        "score": 0.6921
    },
    {
        "class": "chair",
        "box": [
            322.2285,
            395.5607,
            376.729,
            431.6972
        ],
        "score": 0.6884
    },
    {
        "class": "cell phone",
        "box": [
            432.3052,
            374.2716,
            529.8709,
            422.8972
        ],
        "score": 0.6689
    },
    {
        "class": "bowl",
        "box": [
            101.9754,
            255.9895,
            157.0828,
            288.2627
        ],
        "score": 0.6667
    },
    {
        "class": "cup",
        "box": [
            1214.629,
            677.8532,
            1278.6125,
            772.7993
        ],
        "score": 0.5904
    },
    {
        "class": "vase",
        "box": [
            79.1665,
            253.3725,
            95.1909,
            287.541
        ],
        "score": 0.5572
    },
    {
        "class": "dining table",
        "box": [
            370.1022,
            615.9763,
            1278.4908,
            844.7905
        ],
        "score": 0.5459
    }
]`

export const resultJson3 =`[
    {
        "class": "person",
        "box": [
            172.6108,
            1299.3751,
            323.6431,
            1633.6556
        ],
        "score": 0.9992
    },
    {
        "class": "person",
        "box": [
            764.0134,
            1269.6302,
            978.0527,
            1663.6835
        ],
        "score": 0.9992
    },
    {
        "class": "person",
        "box": [
            336.9329,
            1273.3428,
            471.5107,
            1580.4803
        ],
        "score": 0.9988
    },
    {
        "class": "person",
        "box": [
            1039.603,
            1308.1566,
            1148.7069,
            1573.4437
        ],
        "score": 0.9966
    },
    {
        "class": "person",
        "box": [
            522.2166,
            1295.6912,
            740.41,
            1764.8043
        ],
        "score": 0.9964
    },
    {
        "class": "car",
        "box": [
            24.8477,
            1337.3807,
            221.7129,
            1492.7467
        ],
        "score": 0.9876
    },
    {
        "class": "bicycle",
        "box": [
            198.4534,
            1428.1874,
            307.4063,
            1684.2821
        ],
        "score": 0.9847
    },
    {
        "class": "bicycle",
        "box": [
            770.4977,
            1457.5938,
            1013.9573,
            1802.6581
        ],
        "score": 0.9643
    },
    {
        "class": "car",
        "box": [
            482.7975,
            1295.3881,
            579.7429,
            1399.932
        ],
        "score": 0.9387
    },
    {
        "class": "bicycle",
        "box": [
            351.3618,
            1422.5951,
            461.7721,
            1645.8705
        ],
        "score": 0.9385
    },
    {
        "class": "bicycle",
        "box": [
            510.1172,
            1464.924,
            831.2961,
            1876.6461
        ],
        "score": 0.9337
    },
    {
        "class": "car",
        "box": [
            614.4508,
            1308.9901,
            749.9311,
            1410.5438
        ],
        "score": 0.8956
    },
    {
        "class": "person",
        "box": [
            937.9301,
            1335.0751,
            1000.66,
            1463.58
        ],
        "score": 0.8566
    },
    {
        "class": "car",
        "box": [
            283.273,
            1316.7921,
            350.3999,
            1371.5072
        ],
        "score": 0.8553
    },
    {
        "class": "car",
        "box": [
            683.414,
            1367.1202,
            758.5459,
            1430.7343
        ],
        "score": 0.7837
    },
    {
        "class": "car",
        "box": [
            426.9328,
            1308.9843,
            466.0071,
            1356.3557
        ],
        "score": 0.7426
    },
    {
        "class": "person",
        "box": [
            141.4724,
            1300.7108,
            169.1214,
            1328.2728
        ],
        "score": 0.7371
    },
    {
        "class": "car",
        "box": [
            332.2171,
            1302.3051,
            377.9594,
            1329.9224
        ],
        "score": 0.6893
    },
    {
        "class": "car",
        "box": [
            453.566,
            1307.4817,
            492.8604,
            1353.0637
        ],
        "score": 0.5588
    },
    {
        "class": "person",
        "box": [
            123.0467,
            1294.1107,
            150.3508,
            1327.9222
        ],
        "score": 0.5413
    },
    {
        "class": "handbag",
        "box": [
            602.4827,
            1507.9574,
            690.2124,
            1605.8766
        ],
        "score": 0.5296
    }
]`

export const resultJson4 =`[
    {
        "class": "person",
        "box": [
            74.223,
            216.3755,
            182.608,
            524.767
        ],
        "score": 0.9986
    },
    {
        "class": "person",
        "box": [
            1086.7671,
            339.3677,
            1214.9204,
            544.4125
        ],
        "score": 0.9927
    },
    {
        "class": "person",
        "box": [
            1024.6104,
            232.4173,
            1116.1361,
            505.8779
        ],
        "score": 0.975
    },
    {
        "class": "person",
        "box": [
            268.7902,
            368.2395,
            406.9849,
            525.8497
        ],
        "score": 0.9467
    },
    {
        "class": "person",
        "box": [
            163.8076,
            241.151,
            238.4759,
            509.9554
        ],
        "score": 0.9364
    },
    {
        "class": "person",
        "box": [
            802.7108,
            341.6336,
            880.7231,
            457.705
        ],
        "score": 0.9169
    },
    {
        "class": "person",
        "box": [
            878.1882,
            264.8662,
            967.269,
            430.6359
        ],
        "score": 0.9042
    },
    {
        "class": "person",
        "box": [
            962.1898,
            362.6789,
            1087.6932,
            530.2974
        ],
        "score": 0.9021
    },
    {
        "class": "person",
        "box": [
            591.0391,
            385.3219,
            715.9745,
            520.5255
        ],
        "score": 0.8969
    },
    {
        "class": "person",
        "box": [
            190.1136,
            244.0819,
            280.9543,
            502.5983
        ],
        "score": 0.8964
    },
    {
        "class": "person",
        "box": [
            455.2523,
            349.0179,
            535.3115,
            507.1191
        ],
        "score": 0.8505
    },
    {
        "class": "person",
        "box": [
            977.9697,
            265.1126,
            1029.2776,
            399.5222
        ],
        "score": 0.85
    },
    {
        "class": "person",
        "box": [
            213.7405,
            347.4901,
            308.9037,
            518.5756
        ],
        "score": 0.8472
    },
    {
        "class": "person",
        "box": [
            647.6576,
            268.2632,
            718.6907,
            411.4585
        ],
        "score": 0.8349
    },
    {
        "class": "person",
        "box": [
            782.3984,
            276.1421,
            832.9567,
            443.5096
        ],
        "score": 0.8337
    },
    {
        "class": "person",
        "box": [
            693.9337,
            265.5047,
            756.0162,
            412.0952
        ],
        "score": 0.8202
    },
    {
        "class": "person",
        "box": [
            63.8862,
            203.2114,
            145.4029,
            463.7489
        ],
        "score": 0.8057
    },
    {
        "class": "person",
        "box": [
            402.6536,
            344.5226,
            467.0081,
            515.2573
        ],
        "score": 0.7945
    },
    {
        "class": "person",
        "box": [
            702.9654,
            374.1655,
            801.3299,
            492.3229
        ],
        "score": 0.7877
    },
    {
        "class": "person",
        "box": [
            289.5488,
            254.7332,
            360.9405,
            413.9047
        ],
        "score": 0.7814
    },
    {
        "class": "person",
        "box": [
            747.4139,
            278.062,
            788.2593,
            420.6211
        ],
        "score": 0.7714
    },
    {
        "class": "person",
        "box": [
            269.2881,
            245.5012,
            333.0531,
            424.7332
        ],
        "score": 0.7487
    },
    {
        "class": "person",
        "box": [
            521.9386,
            376.3032,
            607.5194,
            515.1159
        ],
        "score": 0.7479
    },
    {
        "class": "person",
        "box": [
            481.5992,
            265.7993,
            529.3837,
            359.7104
        ],
        "score": 0.7371
    },
    {
        "class": "person",
        "box": [
            697.5264,
            363.7094,
            956.006,
            533.9471
        ],
        "score": 0.7103
    },
    {
        "class": "backpack",
        "box": [
            1178.3662,
            395.2499,
            1229.1315,
            475.1361
        ],
        "score": 0.7001
    },
    {
        "class": "person",
        "box": [
            345.1338,
            250.6081,
            431.6133,
            387.4652
        ],
        "score": 0.6881
    },
    {
        "class": "person",
        "box": [
            359.649,
            331.4411,
            450.7726,
            516.0129
        ],
        "score": 0.6788
    },
    {
        "class": "person",
        "box": [
            430.268,
            292.671,
            498.5681,
            391.7702
        ],
        "score": 0.6538
    },
    {
        "class": "person",
        "box": [
            521.123,
            280.073,
            585.0938,
            408.1722
        ],
        "score": 0.6509
    },
    {
        "class": "person",
        "box": [
            848.0644,
            274.6744,
            897.7385,
            402.1515
        ],
        "score": 0.6463
    },
    {
        "class": "person",
        "box": [
            859.7476,
            370.5294,
            957.7751,
            530.0757
        ],
        "score": 0.6289
    },
    {
        "class": "person",
        "box": [
            579.7683,
            258.7408,
            639.1519,
            389.9147
        ],
        "score": 0.6187
    },
    {
        "class": "person",
        "box": [
            934.4554,
            248.4438,
            999.8767,
            446.7802
        ],
        "score": 0.5144
    },
    {
        "class": "person",
        "box": [
            944.2668,
            252.2375,
            1025.5586,
            504.9803
        ],
        "score": 0.5084
    }
]`