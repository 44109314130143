export const resultJson1 =`{
    "objects": [
        "car",
        "person",
        "suitcase",
        "traffic light"
    ],
    "details": [
        {
            "timestamp @ 0s": [
                {
                    "class": "person",
                    "box": [
                        293.8698,
                        171.4172,
                        408.4563,
                        582.9492
                    ],
                    "score": 0.9992
                },
                {
                    "class": "person",
                    "box": [
                        696.7617,
                        105.4024,
                        866.1206,
                        677.8038
                    ],
                    "score": 0.9987
                },
                {
                    "class": "person",
                    "box": [
                        437.1367,
                        134.846,
                        629.3788,
                        598.1685
                    ],
                    "score": 0.9982
                },
                {
                    "class": "person",
                    "box": [
                        1123.6176,
                        173.0738,
                        1250.6848,
                        564.7358
                    ],
                    "score": 0.9977
                },
                {
                    "class": "person",
                    "box": [
                        992.6674,
                        216.9466,
                        1116.8541,
                        559.0189
                    ],
                    "score": 0.9947
                },
                {
                    "class": "person",
                    "box": [
                        835.2434,
                        352.2169,
                        961.1943,
                        516.0472
                    ],
                    "score": 0.9914
                },
                {
                    "class": "person",
                    "box": [
                        411.5079,
                        174.9606,
                        512.9452,
                        573.7906
                    ],
                    "score": 0.9904
                },
                {
                    "class": "car",
                    "box": [
                        3.0547,
                        285.9279,
                        212.4321,
                        438.7656
                    ],
                    "score": 0.9876
                },
                {
                    "class": "suitcase",
                    "box": [
                        398.0599,
                        335.03,
                        487.9261,
                        591.5298
                    ],
                    "score": 0.9857
                },
                {
                    "class": "traffic light",
                    "box": [
                        1191.8142,
                        131.697,
                        1234.1217,
                        206.0115
                    ],
                    "score": 0.9828
                },
                {
                    "class": "person",
                    "box": [
                        598.179,
                        160.7101,
                        727.9999,
                        625.7451
                    ],
                    "score": 0.9687
                },
                {
                    "class": "person",
                    "box": [
                        585.3029,
                        169.9504,
                        653.0389,
                        279.8117
                    ],
                    "score": 0.9456
                },
                {
                    "class": "traffic light",
                    "box": [
                        233.8791,
                        178.7661,
                        263.8186,
                        239.055
                    ],
                    "score": 0.9428
                }
            ]
        },
        {
            "timestamp @ 1s": [
                {
                    "class": "person",
                    "box": [
                        611.4479,
                        97.9247,
                        824.4878,
                        709.3956
                    ],
                    "score": 0.9992
                },
                {
                    "class": "person",
                    "box": [
                        293.639,
                        169.1096,
                        406.9835,
                        582.8045
                    ],
                    "score": 0.9992
                },
                {
                    "class": "person",
                    "box": [
                        442.7123,
                        131.5495,
                        622.8875,
                        600.2589
                    ],
                    "score": 0.9983
                },
                {
                    "class": "person",
                    "box": [
                        1122.4878,
                        174.6289,
                        1252.1287,
                        566.6976
                    ],
                    "score": 0.9978
                },
                {
                    "class": "person",
                    "box": [
                        407.7653,
                        163.9288,
                        512.4409,
                        586.025
                    ],
                    "score": 0.9946
                },
                {
                    "class": "person",
                    "box": [
                        841.3951,
                        350.4001,
                        966.6582,
                        509.0237
                    ],
                    "score": 0.9938
                },
                {
                    "class": "person",
                    "box": [
                        989.4002,
                        216.9155,
                        1112.8643,
                        559.1808
                    ],
                    "score": 0.9928
                },
                {
                    "class": "car",
                    "box": [
                        2.8901,
                        280.2581,
                        212.7437,
                        438.85
                    ],
                    "score": 0.9865
                },
                {
                    "class": "traffic light",
                    "box": [
                        1192.2847,
                        131.5749,
                        1234.114,
                        204.8295
                    ],
                    "score": 0.9826
                },
                {
                    "class": "suitcase",
                    "box": [
                        393.4642,
                        352.3306,
                        480.9264,
                        591.5716
                    ],
                    "score": 0.9714
                },
                {
                    "class": "person",
                    "box": [
                        239.3039,
                        313.6708,
                        264.2794,
                        390.3023
                    ],
                    "score": 0.9677
                },
                {
                    "class": "traffic light",
                    "box": [
                        233.8513,
                        179.3495,
                        263.2773,
                        239.4737
                    ],
                    "score": 0.9464
                }
            ]
        },
        {
            "timestamp @ 2s": [
                {
                    "class": "person",
                    "box": [
                        561.3845,
                        67.3825,
                        775.999,
                        713.3786
                    ],
                    "score": 0.9992
                },
                {
                    "class": "person",
                    "box": [
                        290.5234,
                        161.0148,
                        405.6659,
                        583.9015
                    ],
                    "score": 0.9992
                },
                {
                    "class": "person",
                    "box": [
                        1130.2493,
                        171.0864,
                        1250.8997,
                        568.181
                    ],
                    "score": 0.9971
                },
                {
                    "class": "person",
                    "box": [
                        989.6856,
                        218.3101,
                        1110.7328,
                        555.8411
                    ],
                    "score": 0.9967
                },
                {
                    "class": "person",
                    "box": [
                        841.3588,
                        350.6374,
                        966.6359,
                        510.0781
                    ],
                    "score": 0.9951
                },
                {
                    "class": "suitcase",
                    "box": [
                        397.1951,
                        365.5495,
                        486.6363,
                        590.7129
                    ],
                    "score": 0.9919
                },
                {
                    "class": "traffic light",
                    "box": [
                        1192.5059,
                        131.6201,
                        1234.2484,
                        203.6965
                    ],
                    "score": 0.9835
                },
                {
                    "class": "person",
                    "box": [
                        411.6263,
                        175.5087,
                        502.644,
                        450.0433
                    ],
                    "score": 0.9826
                },
                {
                    "class": "car",
                    "box": [
                        26.8978,
                        297.2256,
                        213.3411,
                        438.4021
                    ],
                    "score": 0.9812
                },
                {
                    "class": "person",
                    "box": [
                        806.2781,
                        312.5666,
                        834.9198,
                        378.4558
                    ],
                    "score": 0.9598
                },
                {
                    "class": "person",
                    "box": [
                        0.7133,
                        36.5979,
                        49.0667,
                        671.9568
                    ],
                    "score": 0.9593
                }
            ]
        },
        {
            "timestamp @ 3s": [
                {
                    "class": "person",
                    "box": [
                        292.2414,
                        168.0791,
                        403.308,
                        582.6654
                    ],
                    "score": 0.9994
                },
                {
                    "class": "person",
                    "box": [
                        492.994,
                        33.238,
                        749.6943,
                        716.9476
                    ],
                    "score": 0.9994
                },
                {
                    "class": "person",
                    "box": [
                        69.1206,
                        63.8614,
                        248.4266,
                        692.6149
                    ],
                    "score": 0.9991
                },
                {
                    "class": "person",
                    "box": [
                        1123.2439,
                        171.5368,
                        1250.9166,
                        566.1426
                    ],
                    "score": 0.9978
                },
                {
                    "class": "person",
                    "box": [
                        835.4807,
                        351.2675,
                        956.4099,
                        517.012
                    ],
                    "score": 0.9942
                },
                {
                    "class": "person",
                    "box": [
                        991.0952,
                        213.7808,
                        1114.585,
                        552.2267
                    ],
                    "score": 0.9919
                },
                {
                    "class": "traffic light",
                    "box": [
                        1192.0354,
                        131.567,
                        1233.7212,
                        203.7846
                    ],
                    "score": 0.9829
                },
                {
                    "class": "car",
                    "box": [
                        1.1587,
                        273.2071,
                        97.5354,
                        438.474
                    ],
                    "score": 0.9829
                },
                {
                    "class": "person",
                    "box": [
                        404.6233,
                        172.5981,
                        491.6566,
                        326.6401
                    ],
                    "score": 0.9751
                },
                {
                    "class": "person",
                    "box": [
                        781.8016,
                        309.986,
                        808.3629,
                        375.3484
                    ],
                    "score": 0.9511
                }
            ]
        },
        {
            "timestamp @ 4s": [
                {
                    "class": "person",
                    "box": [
                        406.3465,
                        0.4893,
                        697.5464,
                        708.5263
                    ],
                    "score": 0.9993
                },
                {
                    "class": "person",
                    "box": [
                        936.5114,
                        0.0,
                        1274.1256,
                        701.4825
                    ],
                    "score": 0.9957
                },
                {
                    "class": "person",
                    "box": [
                        829.6757,
                        353.3685,
                        956.6331,
                        510.5151
                    ],
                    "score": 0.9956
                },
                {
                    "class": "person",
                    "box": [
                        650.7351,
                        175.1252,
                        743.9066,
                        552.7881
                    ],
                    "score": 0.9929
                },
                {
                    "class": "car",
                    "box": [
                        1.4424,
                        279.675,
                        211.1566,
                        439.4545
                    ],
                    "score": 0.9846
                },
                {
                    "class": "person",
                    "box": [
                        764.584,
                        308.0648,
                        787.4208,
                        376.9716
                    ],
                    "score": 0.9802
                },
                {
                    "class": "person",
                    "box": [
                        783.7283,
                        310.0345,
                        806.3519,
                        376.7855
                    ],
                    "score": 0.9477
                },
                {
                    "class": "person",
                    "box": [
                        269.3495,
                        64.6172,
                        462.5036,
                        714.3302
                    ],
                    "score": 0.9414
                }
            ]
        },
        {
            "timestamp @ 5s": [
                {
                    "class": "person",
                    "box": [
                        284.9479,
                        4.1476,
                        620.2876,
                        717.4116
                    ],
                    "score": 0.9989
                },
                {
                    "class": "person",
                    "box": [
                        602.6579,
                        167.7856,
                        743.5087,
                        572.228
                    ],
                    "score": 0.9989
                },
                {
                    "class": "person",
                    "box": [
                        86.7519,
                        4.3353,
                        358.7645,
                        716.3812
                    ],
                    "score": 0.9968
                },
                {
                    "class": "person",
                    "box": [
                        746.9271,
                        0.6334,
                        1272.8092,
                        713.2057
                    ],
                    "score": 0.9752
                },
                {
                    "class": "car",
                    "box": [
                        0.8583,
                        290.1854,
                        102.9764,
                        424.7681
                    ],
                    "score": 0.9607
                }
            ]
        },
        {
            "timestamp @ 6s": [
                {
                    "class": "person",
                    "box": [
                        1096.1049,
                        176.3082,
                        1217.1959,
                        568.6783
                    ],
                    "score": 0.9991
                },
                {
                    "class": "person",
                    "box": [
                        928.1668,
                        216.0338,
                        1076.9149,
                        553.4445
                    ],
                    "score": 0.9964
                },
                {
                    "class": "person",
                    "box": [
                        791.4127,
                        354.6996,
                        903.2992,
                        528.3168
                    ],
                    "score": 0.9926
                },
                {
                    "class": "traffic light",
                    "box": [
                        1190.4495,
                        131.5228,
                        1233.2977,
                        204.8518
                    ],
                    "score": 0.9891
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        2.9289,
                        151.6575,
                        663.1032
                    ],
                    "score": 0.9604
                },
                {
                    "class": "person",
                    "box": [
                        135.1843,
                        0.0,
                        816.8197,
                        720.0
                    ],
                    "score": 0.9404
                }
            ]
        },
        {
            "timestamp @ 7s": [
                {
                    "class": "person",
                    "box": [
                        696.3818,
                        143.7294,
                        898.1242,
                        628.8599
                    ],
                    "score": 0.999
                },
                {
                    "class": "person",
                    "box": [
                        1079.0623,
                        168.8266,
                        1203.1426,
                        566.3582
                    ],
                    "score": 0.999
                },
                {
                    "class": "person",
                    "box": [
                        582.3107,
                        146.658,
                        714.5519,
                        585.13
                    ],
                    "score": 0.9986
                },
                {
                    "class": "person",
                    "box": [
                        287.7443,
                        160.7366,
                        404.5252,
                        585.316
                    ],
                    "score": 0.9976
                },
                {
                    "class": "person",
                    "box": [
                        428.722,
                        134.1032,
                        546.6309,
                        602.8791
                    ],
                    "score": 0.9972
                },
                {
                    "class": "person",
                    "box": [
                        880.9176,
                        213.5086,
                        1044.7356,
                        574.5839
                    ],
                    "score": 0.9967
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        2.2501,
                        274.1158,
                        709.58
                    ],
                    "score": 0.9919
                },
                {
                    "class": "person",
                    "box": [
                        500.0158,
                        143.726,
                        591.798,
                        567.3765
                    ],
                    "score": 0.9913
                },
                {
                    "class": "traffic light",
                    "box": [
                        1190.3497,
                        132.0259,
                        1233.3835,
                        205.1368
                    ],
                    "score": 0.9891
                },
                {
                    "class": "suitcase",
                    "box": [
                        383.6565,
                        445.5261,
                        459.4978,
                        583.0641
                    ],
                    "score": 0.9724
                },
                {
                    "class": "person",
                    "box": [
                        369.5651,
                        168.6349,
                        456.4472,
                        509.5209
                    ],
                    "score": 0.9429
                }
            ]
        }
    ]
}`

export const resultJson2 =`{
    "objects": [
        "cup",
        "dining table",
        "laptop",
        "person"
    ],
    "details": [
        {
            "timestamp @ 0s": [
                {
                    "class": "cup",
                    "box": [
                        843.4589,
                        299.9975,
                        1161.1503,
                        624.6043
                    ],
                    "score": 0.9993
                },
                {
                    "class": "dining table",
                    "box": [
                        261.9068,
                        140.1291,
                        1276.8899,
                        710.4763
                    ],
                    "score": 0.9528
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        65.6104,
                        717.7807,
                        708.8654
                    ],
                    "score": 0.9485
                }
            ]
        },
        {
            "timestamp @ 1s": [
                {
                    "class": "cup",
                    "box": [
                        787.2807,
                        294.0705,
                        1118.4706,
                        623.2335
                    ],
                    "score": 0.9991
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        167.2227,
                        826.7774,
                        711.84
                    ],
                    "score": 0.9594
                }
            ]
        },
        {
            "timestamp @ 2s": [
                {
                    "class": "cup",
                    "box": [
                        782.2293,
                        257.851,
                        1117.4984,
                        592.553
                    ],
                    "score": 0.9991
                },
                {
                    "class": "laptop",
                    "box": [
                        445.5397,
                        0.0,
                        950.3589,
                        489.8604
                    ],
                    "score": 0.9923
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        67.0084,
                        712.9409,
                        701.4271
                    ],
                    "score": 0.9912
                }
            ]
        },
        {
            "timestamp @ 3s": [
                {
                    "class": "cup",
                    "box": [
                        777.8011,
                        255.0232,
                        1114.1117,
                        600.2449
                    ],
                    "score": 0.9987
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        109.8508,
                        753.7131,
                        691.77
                    ],
                    "score": 0.9934
                },
                {
                    "class": "laptop",
                    "box": [
                        457.9612,
                        20.3428,
                        1001.3808,
                        490.0936
                    ],
                    "score": 0.9679
                }
            ]
        },
        {
            "timestamp @ 4s": [
                {
                    "class": "cup",
                    "box": [
                        768.4304,
                        278.3382,
                        1102.3436,
                        635.952
                    ],
                    "score": 0.9977
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        68.0413,
                        754.4629,
                        712.0563
                    ],
                    "score": 0.9898
                },
                {
                    "class": "laptop",
                    "box": [
                        440.0344,
                        0.6449,
                        1051.3357,
                        543.8288
                    ],
                    "score": 0.9739
                }
            ]
        },
        {
            "timestamp @ 5s": [
                {
                    "class": "cup",
                    "box": [
                        790.1329,
                        307.4552,
                        1143.8329,
                        669.5975
                    ],
                    "score": 0.9984
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        116.8289,
                        742.2084,
                        713.3219
                    ],
                    "score": 0.9875
                },
                {
                    "class": "laptop",
                    "box": [
                        409.6149,
                        8.5706,
                        1147.6176,
                        574.8098
                    ],
                    "score": 0.9792
                }
            ]
        },
        {
            "timestamp @ 6s": [
                {
                    "class": "cup",
                    "box": [
                        796.902,
                        325.6141,
                        1156.213,
                        700.111
                    ],
                    "score": 0.9991
                },
                {
                    "class": "person",
                    "box": [
                        1.2512,
                        128.6763,
                        670.4062,
                        720.0
                    ],
                    "score": 0.9822
                },
                {
                    "class": "laptop",
                    "box": [
                        417.5004,
                        11.7806,
                        1165.3086,
                        575.0599
                    ],
                    "score": 0.9748
                }
            ]
        },
        {
            "timestamp @ 7s": [
                {
                    "class": "cup",
                    "box": [
                        793.3951,
                        333.8016,
                        1160.2169,
                        711.7119
                    ],
                    "score": 0.9987
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        68.9636,
                        618.4186,
                        714.428
                    ],
                    "score": 0.9917
                },
                {
                    "class": "laptop",
                    "box": [
                        370.3372,
                        4.6169,
                        1247.9397,
                        585.1473
                    ],
                    "score": 0.9787
                }
            ]
        },
        {
            "timestamp @ 8s": [
                {
                    "class": "cup",
                    "box": [
                        782.1551,
                        333.2739,
                        1157.3153,
                        709.063
                    ],
                    "score": 0.999
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        89.7744,
                        584.2856,
                        694.9903
                    ],
                    "score": 0.9774
                }
            ]
        },
        {
            "timestamp @ 9s": [
                {
                    "class": "cup",
                    "box": [
                        801.0312,
                        324.0053,
                        1170.5081,
                        705.119
                    ],
                    "score": 0.9987
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        81.8895,
                        588.6036,
                        693.1756
                    ],
                    "score": 0.9777
                },
                {
                    "class": "laptop",
                    "box": [
                        365.5963,
                        0.0,
                        1279.2441,
                        600.7041
                    ],
                    "score": 0.9519
                }
            ]
        },
        {
            "timestamp @ 10s": [
                {
                    "class": "cup",
                    "box": [
                        842.0308,
                        317.6393,
                        1211.3702,
                        694.9647
                    ],
                    "score": 0.9975
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        73.8481,
                        629.0659,
                        689.9591
                    ],
                    "score": 0.9818
                },
                {
                    "class": "laptop",
                    "box": [
                        402.1309,
                        1.6545,
                        1273.9458,
                        571.0809
                    ],
                    "score": 0.9754
                }
            ]
        },
        {
            "timestamp @ 11s": [
                {
                    "class": "cup",
                    "box": [
                        879.2003,
                        330.3511,
                        1245.7313,
                        706.1223
                    ],
                    "score": 0.9983
                },
                {
                    "class": "person",
                    "box": [
                        2.1492,
                        75.9277,
                        673.4098,
                        705.0486
                    ],
                    "score": 0.9941
                },
                {
                    "class": "laptop",
                    "box": [
                        453.7665,
                        14.6277,
                        1243.9841,
                        591.5175
                    ],
                    "score": 0.9771
                }
            ]
        }
    ]
}`

export const resultJson3 =`{
    "objects": [
        "cake",
        "cell phone",
        "cup",
        "person"
    ],
    "details": [
        {
            "timestamp @ 0s": [
                {
                    "class": "person",
                    "box": [
                        464.5132,
                        42.0316,
                        912.7632,
                        644.9755
                    ],
                    "score": 0.9948
                },
                {
                    "class": "person",
                    "box": [
                        6.4007,
                        0.7159,
                        627.054,
                        715.9877
                    ],
                    "score": 0.9915
                },
                {
                    "class": "person",
                    "box": [
                        1073.6816,
                        7.3137,
                        1277.2207,
                        515.4591
                    ],
                    "score": 0.9882
                },
                {
                    "class": "person",
                    "box": [
                        885.7647,
                        112.0484,
                        1096.6262,
                        466.6794
                    ],
                    "score": 0.9874
                },
                {
                    "class": "cake",
                    "box": [
                        793.7169,
                        469.7841,
                        1136.7756,
                        714.0353
                    ],
                    "score": 0.9844
                }
            ]
        },
        {
            "timestamp @ 1s": [
                {
                    "class": "person",
                    "box": [
                        468.2688,
                        29.2982,
                        919.3655,
                        614.965
                    ],
                    "score": 0.9956
                },
                {
                    "class": "person",
                    "box": [
                        79.4615,
                        1.2042,
                        811.0358,
                        689.7823
                    ],
                    "score": 0.992
                },
                {
                    "class": "person",
                    "box": [
                        950.1293,
                        98.5581,
                        1157.3904,
                        438.8826
                    ],
                    "score": 0.9758
                },
                {
                    "class": "cake",
                    "box": [
                        799.5432,
                        440.2085,
                        1152.1882,
                        708.9955
                    ],
                    "score": 0.95
                },
                {
                    "class": "person",
                    "box": [
                        874.5388,
                        216.329,
                        959.4142,
                        420.4019
                    ],
                    "score": 0.9465
                },
                {
                    "class": "person",
                    "box": [
                        1104.5417,
                        3.4698,
                        1279.5664,
                        540.0005
                    ],
                    "score": 0.931
                }
            ]
        },
        {
            "timestamp @ 2s": [
                {
                    "class": "person",
                    "box": [
                        438.7319,
                        22.3261,
                        917.2637,
                        630.4457
                    ],
                    "score": 0.9952
                },
                {
                    "class": "person",
                    "box": [
                        97.5628,
                        6.9079,
                        875.4384,
                        683.7995
                    ],
                    "score": 0.9952
                },
                {
                    "class": "cake",
                    "box": [
                        805.4128,
                        477.106,
                        1155.6327,
                        709.6998
                    ],
                    "score": 0.9828
                },
                {
                    "class": "person",
                    "box": [
                        1041.3326,
                        91.3143,
                        1246.2224,
                        503.5426
                    ],
                    "score": 0.9768
                },
                {
                    "class": "person",
                    "box": [
                        0.0,
                        171.7698,
                        82.5221,
                        562.7728
                    ],
                    "score": 0.9718
                },
                {
                    "class": "person",
                    "box": [
                        966.2179,
                        216.1079,
                        1050.8271,
                        438.7921
                    ],
                    "score": 0.9407
                },
                {
                    "class": "person",
                    "box": [
                        735.8674,
                        60.7422,
                        960.5848,
                        437.7803
                    ],
                    "score": 0.9305
                }
            ]
        },
        {
            "timestamp @ 3s": [
                {
                    "class": "person",
                    "box": [
                        0.3648,
                        21.02,
                        155.8978,
                        709.779
                    ],
                    "score": 0.9992
                },
                {
                    "class": "person",
                    "box": [
                        100.9985,
                        3.7764,
                        829.3002,
                        718.7932
                    ],
                    "score": 0.9962
                },
                {
                    "class": "cake",
                    "box": [
                        775.391,
                        481.3299,
                        1136.6726,
                        707.8181
                    ],
                    "score": 0.9921
                },
                {
                    "class": "person",
                    "box": [
                        436.0057,
                        23.4393,
                        894.8609,
                        634.7811
                    ],
                    "score": 0.9915
                },
                {
                    "class": "person",
                    "box": [
                        1094.714,
                        93.1663,
                        1279.5696,
                        564.7184
                    ],
                    "score": 0.99
                },
                {
                    "class": "person",
                    "box": [
                        785.5142,
                        25.0131,
                        1006.6502,
                        463.8101
                    ],
                    "score": 0.9846
                }
            ]
        },
        {
            "timestamp @ 4s": [
                {
                    "class": "person",
                    "box": [
                        0.0,
                        49.0001,
                        248.5689,
                        716.1221
                    ],
                    "score": 0.9989
                },
                {
                    "class": "cake",
                    "box": [
                        817.9729,
                        487.1118,
                        1175.5481,
                        701.3734
                    ],
                    "score": 0.9945
                },
                {
                    "class": "person",
                    "box": [
                        332.335,
                        16.2367,
                        896.5383,
                        696.3674
                    ],
                    "score": 0.994
                },
                {
                    "class": "person",
                    "box": [
                        865.0556,
                        25.6721,
                        1066.359,
                        474.4559
                    ],
                    "score": 0.9684
                }
            ]
        },
        {
            "timestamp @ 5s": [
                {
                    "class": "person",
                    "box": [
                        38.2033,
                        20.0947,
                        300.9016,
                        707.0524
                    ],
                    "score": 0.9989
                },
                {
                    "class": "person",
                    "box": [
                        299.9929,
                        5.6363,
                        904.2972,
                        702.1601
                    ],
                    "score": 0.9959
                },
                {
                    "class": "person",
                    "box": [
                        878.421,
                        22.3526,
                        1112.9583,
                        464.2485
                    ],
                    "score": 0.9911
                },
                {
                    "class": "cake",
                    "box": [
                        800.0667,
                        506.635,
                        1166.316,
                        709.3704
                    ],
                    "score": 0.9842
                }
            ]
        },
        {
            "timestamp @ 6s": [
                {
                    "class": "person",
                    "box": [
                        925.4259,
                        20.3231,
                        1223.4155,
                        561.8223
                    ],
                    "score": 0.9977
                },
                {
                    "class": "person",
                    "box": [
                        269.5792,
                        0.0,
                        876.9068,
                        708.6161
                    ],
                    "score": 0.9972
                },
                {
                    "class": "person",
                    "box": [
                        105.4817,
                        46.5224,
                        336.7282,
                        659.2718
                    ],
                    "score": 0.9943
                },
                {
                    "class": "cup",
                    "box": [
                        941.5592,
                        263.1625,
                        994.441,
                        328.8374
                    ],
                    "score": 0.9765
                },
                {
                    "class": "person",
                    "box": [
                        543.0275,
                        10.3079,
                        932.6805,
                        658.4781
                    ],
                    "score": 0.9763
                },
                {
                    "class": "cake",
                    "box": [
                        780.1754,
                        467.9047,
                        1162.9244,
                        701.5975
                    ],
                    "score": 0.949
                }
            ]
        },
        {
            "timestamp @ 7s": [
                {
                    "class": "person",
                    "box": [
                        229.3401,
                        13.9732,
                        962.9658,
                        700.8035
                    ],
                    "score": 0.9979
                },
                {
                    "class": "person",
                    "box": [
                        1038.5503,
                        5.4106,
                        1276.4476,
                        576.3702
                    ],
                    "score": 0.9964
                },
                {
                    "class": "person",
                    "box": [
                        191.9557,
                        56.0348,
                        399.9103,
                        698.3143
                    ],
                    "score": 0.9959
                },
                {
                    "class": "cup",
                    "box": [
                        1034.1918,
                        264.9241,
                        1086.4283,
                        330.2114
                    ],
                    "score": 0.9824
                },
                {
                    "class": "cake",
                    "box": [
                        775.2587,
                        493.1158,
                        1165.7543,
                        702.2491
                    ],
                    "score": 0.9776
                }
            ]
        },
        {
            "timestamp @ 8s": [
                {
                    "class": "person",
                    "box": [
                        296.7162,
                        3.3508,
                        1019.2482,
                        689.1154
                    ],
                    "score": 0.9985
                },
                {
                    "class": "person",
                    "box": [
                        1109.299,
                        7.2998,
                        1276.285,
                        606.4118
                    ],
                    "score": 0.9983
                },
                {
                    "class": "cup",
                    "box": [
                        1147.239,
                        262.4497,
                        1205.849,
                        326.8715
                    ],
                    "score": 0.9915
                },
                {
                    "class": "person",
                    "box": [
                        279.9952,
                        58.6611,
                        465.596,
                        370.3963
                    ],
                    "score": 0.9872
                },
                {
                    "class": "person",
                    "box": [
                        806.2601,
                        172.5777,
                        1056.087,
                        615.8216
                    ],
                    "score": 0.9793
                }
            ]
        },
        {
            "timestamp @ 9s": [
                {
                    "class": "person",
                    "box": [
                        330.8834,
                        1.4633,
                        963.8267,
                        710.105
                    ],
                    "score": 0.9978
                },
                {
                    "class": "person",
                    "box": [
                        869.1652,
                        185.4024,
                        1134.8577,
                        652.1097
                    ],
                    "score": 0.9941
                },
                {
                    "class": "person",
                    "box": [
                        1169.7301,
                        138.7141,
                        1280.0,
                        638.1091
                    ],
                    "score": 0.9927
                },
                {
                    "class": "cake",
                    "box": [
                        730.3079,
                        504.6953,
                        1027.4883,
                        700.4708
                    ],
                    "score": 0.9508
                }
            ]
        },
        {
            "timestamp @ 10s": [
                {
                    "class": "cell phone",
                    "box": [
                        1124.0582,
                        285.2005,
                        1183.1019,
                        350.0307
                    ],
                    "score": 0.9952
                },
                {
                    "class": "person",
                    "box": [
                        362.1335,
                        16.8215,
                        1113.9222,
                        703.1019
                    ],
                    "score": 0.9917
                },
                {
                    "class": "person",
                    "box": [
                        960.8713,
                        203.5688,
                        1275.8724,
                        709.9604
                    ],
                    "score": 0.9859
                }
            ]
        }
    ]
}`

export const resultJson4 =`{
    "objects": [
        "airplane"
    ],
    "details": [
        {
            "timestamp @ 0s": [
                {
                    "class": "airplane",
                    "box": [
                        463.3098,
                        322.8346,
                        788.6504,
                        392.2428
                    ],
                    "score": 0.9977
                }
            ]
        },
        {
            "timestamp @ 1s": [
                {
                    "class": "airplane",
                    "box": [
                        420.5629,
                        295.5713,
                        791.6238,
                        399.1063
                    ],
                    "score": 0.9984
                }
            ]
        },
        {
            "timestamp @ 2s": [
                {
                    "class": "airplane",
                    "box": [
                        369.9221,
                        284.177,
                        755.3912,
                        403.1081
                    ],
                    "score": 0.998
                },
                {
                    "class": "airplane",
                    "box": [
                        891.5623,
                        360.912,
                        1034.886,
                        425.5945
                    ],
                    "score": 0.9931
                },
                {
                    "class": "airplane",
                    "box": [
                        1102.1946,
                        389.1454,
                        1218.1907,
                        430.0295
                    ],
                    "score": 0.9588
                }
            ]
        },
        {
            "timestamp @ 3s": [
                {
                    "class": "airplane",
                    "box": [
                        257.5988,
                        226.8959,
                        751.6627,
                        382.1426
                    ],
                    "score": 0.9988
                },
                {
                    "class": "airplane",
                    "box": [
                        411.4245,
                        394.786,
                        550.0074,
                        461.0291
                    ],
                    "score": 0.9875
                },
                {
                    "class": "airplane",
                    "box": [
                        612.3351,
                        425.3364,
                        706.7176,
                        462.0328
                    ],
                    "score": 0.9773
                }
            ]
        },
        {
            "timestamp @ 4s": [
                {
                    "class": "airplane",
                    "box": [
                        185.5984,
                        173.9607,
                        877.0572,
                        391.111
                    ],
                    "score": 0.9995
                }
            ]
        },
        {
            "timestamp @ 5s": [
                {
                    "class": "airplane",
                    "box": [
                        162.898,
                        151.4707,
                        996.4196,
                        382.6429
                    ],
                    "score": 0.9983
                }
            ]
        },
        {
            "timestamp @ 6s": [
                {
                    "class": "airplane",
                    "box": [
                        235.3448,
                        170.1478,
                        986.0632,
                        380.6359
                    ],
                    "score": 0.9996
                }
            ]
        },
        {
            "timestamp @ 7s": [
                {
                    "class": "airplane",
                    "box": [
                        377.9742,
                        248.216,
                        862.3831,
                        396.4203
                    ],
                    "score": 0.9994
                }
            ]
        }
    ]
}`